import React, { useState } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';

import {
  Toggle,
  DateTimePicker,
  Label,
  Flex,
  LeftHalfLayout,
  RightHalfLayout,
  FormGroup
} from '@ubisend/pulse-components';
import {
  ComposerSettings,
  Composer,
  ComposerSubmitButton,
  ComposerWidget,
  ComposerProvider,
  ComposerLanguageSelect
} from '@ubisend/pulse-composer';
import { AppComposerWidget } from '@ubisend/pulse-bot';

const EditBroadcast = ({ broadcast, handleEditSubmit }) => {
  const [sendNow, setSendNow] = useState(false);
  const [scheduleAt, setScheduleAt] = useState(
    new Date(broadcast.scheduled_at)
  );

  const handleScheduleAtChange = ([date]) => {
    if (dayjs(date).isAfter(dayjs())) {
      setScheduleAt(date);
    }
  };

  const handleSendNowChange = () => setSendNow(sendNow => !sendNow);

  const handleBroadcastSubmit = responses => {
    handleEditSubmit({
      id: broadcast.id,
      sendNow,
      scheduleAt: dayjs(scheduleAt).format('YYYY-MM-DD HH:mm:ss'),
      responses
    });
  };

  return (
    <ComposerProvider initalResponses={broadcast.responses}>
      <Flex>
        <LeftHalfLayout>
          <FormGroup>
            <Label htmlFor="language">Language</Label>
            <ComposerLanguageSelect id="language" />
          </FormGroup>
          <Composer />
          <Flex center between mb>
            <span>Broadcast Message Now?</span>
            <Toggle
              on={sendNow}
              handleToggleChange={handleSendNowChange}
              options={['No', 'Yes']}
            />
          </Flex>
          {!sendNow && (
            <Flex mb col>
              <Label>Deliver Message At</Label>
              <DateTimePicker
                date={scheduleAt}
                onChange={handleScheduleAtChange}
              />
            </Flex>
          )}
          <Flex xSpace>
            <ComposerSettings />
            <ComposerSubmitButton onClick={handleBroadcastSubmit}>
              {sendNow ? 'Send Broadcast' : 'Update Broadcast'}
            </ComposerSubmitButton>
          </Flex>
        </LeftHalfLayout>
        <RightHalfLayout>
          <Flex fat middle>
            <ComposerWidget as={AppComposerWidget} />
          </Flex>
        </RightHalfLayout>
      </Flex>
    </ComposerProvider>
  );
};

EditBroadcast.propTypes = {
  broadcast: PropTypes.shape({
    id: PropTypes.number.isRequired,
    scheduled_at: PropTypes.string.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    responses: PropTypes.array.isRequired
  }).isRequired,
  handleEditSubmit: PropTypes.func.isRequired
};

export default EditBroadcast;
