import React from 'react';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';

import { useQuery } from '@ubisend/pulse-hooks';
import {
  PageWrapper,
  StretchPanel as Panel,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Pagination,
  Button,
  NoResults,
  TableActions,
  Flex,
  usePaginationReducer,
  OrderableTableRow,
  useOrderableTableReducer,
  useFilterReducer,
  FilterMenu,
  Label,
  Select,
  DateTimePicker
} from '@ubisend/pulse-components';
import { PermissionFilter, FeatureFilter } from '@ubisend/pulse-auth';

import SubscribersRouter from './SubscribersRouter';

const TYPES = {
  REGULAR: {
    name: 'Regular',
    value: 'REGULAR',
    params: {
      type: 'regular'
    }
  },
  DEMO: {
    name: 'Demo',
    value: 'DEMO',
    params: {
      type: 'demo'
    }
  }
};

const AUTHORISATION_STATUSES = [
  { label: 'Any', value: null },
  { label: 'Authorised', value: true },
  { label: 'Not authorised', value: false }
];

const defaultFilters = {
  type: TYPES.REGULAR.value,
  authorised: null,
  start: new Date(dayjs().subtract(1, 'weeks')),
  end: new Date(dayjs().add('1', 'hours'))
};

const Subscribers = () => {
  const pagination = usePaginationReducer({ id: 'subscribers' });
  const order = useOrderableTableReducer({ id: 'subscribers' });
  const filters = useFilterReducer({
    id: 'subscribers',
    initialFilters: defaultFilters,
    options: { pagination }
  });

  const query = useQuery(
    [
      'subscribers',
      {
        start: filters.form.start,
        end: filters.form.end,
        authorised: filters.form.authorised,
        ...TYPES[filters.form.type].params,
        ...pagination.params,
        ...order.params
      }
    ],
    { cacheTime: 0 }
  );

  const handleTypeChange = option => {
    filters.updateFilters({
      type: option.value
    });
  };

  const handleAuthorisationStatusChange = option => {
    filters.updateFilters({
      authorised: option.value
    });
  };

  const handleNewStart = ([newStart]) => {
    filters.updateFilters({
      start: newStart
    });
  };

  const handleNewEnd = ([newEnd]) => {
    filters.updateFilters({
      end: newEnd
    });
  };

  return (
    <>
      <SubscribersRouter />
      <PageWrapper
        header="Conversations"
        subheader="View your chatbot's conversations"
        actions={
          <FilterMenu
            position={FilterMenu.POSITIONS.LEFT}
            buttonProps={{ loading: query.isLoading }}
            {...filters.props}>
            <Flex col style={{ width: '20rem' }}>
              <Flex col fat mb>
                <Label htmlFor="type">User type</Label>
                <Select
                  id="type"
                  value={{
                    label: TYPES[filters.filters.type].name,
                    value: filters.filters.type
                  }}
                  options={Object.values(TYPES).map(type => {
                    return { label: type.name, value: type.value };
                  })}
                  onChange={handleTypeChange}
                />
              </Flex>
              <FeatureFilter feature="authorisation">
                <Flex col fat mb>
                  <Label htmlFor="type">Authorisation status</Label>
                  <Select
                    id="type"
                    value={AUTHORISATION_STATUSES.find(
                      status => filters.filters.authorised === status.value
                    )}
                    options={AUTHORISATION_STATUSES}
                    onChange={handleAuthorisationStatusChange}
                  />
                </Flex>
              </FeatureFilter>
              <Flex col fat mb>
                <Label htmlFor="start">Start</Label>
                <DateTimePicker
                  id="start"
                  date={filters.filters.start}
                  onChange={handleNewStart}
                />
              </Flex>
              <Flex col fat>
                <Label htmlFor="end">End</Label>
                <DateTimePicker
                  id="end"
                  date={filters.filters.end}
                  onChange={handleNewEnd}
                />
              </Flex>
            </Flex>
          </FilterMenu>
        }>
        <Flex col ySpace>
          <Panel>
            {query.showNoResultsMessage && <NoResults />}
            {query.showTable && (
              <>
                <Table loading={query.isLoading} loadingColumns={5}>
                  <TableHead>
                    <OrderableTableRow
                      rows={[
                        {
                          label: 'Last message sent at',
                          sort: 'last_active_at'
                        },
                        {
                          label: 'Conversation Started on',
                          sort: 'created_at'
                        },
                        { label: 'Channel', sort: null },
                        { label: 'Messages sent', sort: 'messages_count' },
                        null
                      ]}
                      {...order.props}
                    />
                  </TableHead>

                  {query.isSuccess && (
                    <TableBody>
                      {query.data.data.map((subscriber, key) => (
                        <TableRow key={key}>
                          <TableCell>
                            {subscriber.last_active_at
                              ? `${new Date(
                                  subscriber.last_active_at
                                ).toDateString()} (${dayjs(
                                  subscriber.last_active_at
                                ).from(dayjs())})`
                              : 'N/A'}
                          </TableCell>
                          <TableCell>
                            {new Date(subscriber.created_at).toDateString()}
                          </TableCell>
                          <TableCell>
                            {subscriber.channel?.driver?.name || 'N/A'}
                          </TableCell>
                          <TableCell>{subscriber.message_count}</TableCell>
                          <TableActions>
                            <PermissionFilter can="view messages">
                              <Button
                                as={Link}
                                variant="secondary"
                                to={`/conversations/${subscriber.id}`}
                                icon="eye">
                                View
                              </Button>
                            </PermissionFilter>
                          </TableActions>
                        </TableRow>
                      ))}
                    </TableBody>
                  )}
                </Table>
                {query.showPagination && (
                  <Pagination
                    pagination={query.data.meta}
                    {...pagination.props}
                  />
                )}
              </>
            )}
          </Panel>
        </Flex>
      </PageWrapper>
    </>
  );
};

export default Subscribers;
