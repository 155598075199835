import React, { useMemo } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { breakpoints } from '@ubisend/pulse-components';

import { Content, Embed } from './ResponseTypes/index';
import { useHasContentMessage } from '../hooks/index';

const types = {
  content: Content,
  embed: Embed
};

const MessagePanel = styled.div`
  ${tw`relative w-full bg-white lg:h-full flex flex-col text-black lg:overflow-y-auto w-full`}
  box-sizing: border-box;
  height: 25vh;

  @media (min-width: ${breakpoints.xl}px) {
    width: 40rem;
  }
`;

const ContentMessage = ({ message }) => {
  const Component = useMemo(() => types[message.type], [message.type]);

  return (
    <MessagePanel layout layoutId="panel">
      <Component content={message.content} />
    </MessagePanel>
  );
};

ContentMessage.propTypes = {
  message: PropTypes.shape({
    type: PropTypes.string.isRequired,
    content: PropTypes.object.isRequired
  }).isRequired
};

const ContentMessageSlideInPortal = ({ children }) => {
  if (process.env.NODE_ENV === 'test') {
    return children;
  }

  const element = document.getElementById('content-message-slide-in');

  if (!element) {
    return null;
  }

  return ReactDOM.createPortal(children, element);
};

const ContentMessageSlideIn = ({ message }) => {
  const hasContentMessage = useHasContentMessage(message);

  return (
    <ContentMessageSlideInPortal>
      {hasContentMessage ? <ContentMessage message={message} /> : null}
    </ContentMessageSlideInPortal>
  );
};

ContentMessageSlideIn.propTypes = {
  message: PropTypes.object.isRequired
};

export default ContentMessageSlideIn;
