import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import chroma from 'chroma-js';

import {
  Label,
  Flex,
  Button,
  TextInput,
  AutoTextArea,
  HiddenInput,
  Divider,
  Span
} from '@ubisend/pulse-components';
import { GroupSelect } from '@ubisend/pulse-groups';

import { useFile, useFileSource } from '../hooks/index';

// https://stackoverflow.com/a/20732091
const bytesToReadbaleSize = size => {
  const i = Math.floor(Math.log(size) / Math.log(1024));
  return (size / Math.pow(1024, i)).toFixed(2) * 1 + ' ' + ['B', 'kB', 'MB'][i];
};

const StyledBox = styled.label`
  ${tw`w-full py-8 px-8 border-grey-medium border-dashed border cursor-pointer`}
  &:not(:disabled):hover {
    background: ${({ theme }) => {
      return chroma.mix('white', theme.primary, 0.125);
    }};
  }
  &:not(:disabled):active {
    background: ${({ theme }) => {
      return chroma.mix('white', theme.primary, 0.25);
    }};
  }
`;

const FileDetails = ({ loading, onSubmit, showUpload = true }) => {
  const { file, form, valid, dispatch } = useFile();
  const { driver } = useFileSource();

  const handleSubmit = event => {
    event.preventDefault();
    event.stopPropagation();

    if (!valid || loading) {
      return;
    }

    onSubmit(form);
  };

  const handleFileUpload = event => {
    const file = event.target.files[0];
    dispatch({ type: 'UPLOAD_FILE', file });
  };

  const handleNameChange = event => {
    const name = event.target.value;

    dispatch({ type: 'UPDATE_NAME', name });
  };

  const handleDescriptionChange = event => {
    const description = event.target.value;

    dispatch({ type: 'UPDATE_DESCRIPTION', description });
  };

  const handleGroupsChange = groups => {
    dispatch({
      type: 'UPDATE_GROUP_IDS',
      groupIds: groups ? groups.map(group => group.value) : []
    });
  };

  return (
    <form onSubmit={handleSubmit}>
      <Flex col ySpace pad fat>
        {showUpload && (
          <>
            <Flex fat>
              <HiddenInput type="file" id="file" onChange={handleFileUpload} />
              <StyledBox htmlFor="file" aria-label="Select a file">
                <Flex col ySpaceSm>
                  <Span>{file.file ? file.file.name : '+ Select a file'}</Span>
                  {file.file && (
                    <Span tinyText light>
                      Last modified{' '}
                      {dayjs(file.file.lastModified).format('DD-MM-YYYY')} (
                      {dayjs(file.file.lastModified).from()})
                    </Span>
                  )}
                  {file.file && (
                    <Span tinyText light>
                      {bytesToReadbaleSize(file.file.size)}
                    </Span>
                  )}
                </Flex>
              </StyledBox>
            </Flex>
            <Flex>
              <Divider fullWidth />
            </Flex>
          </>
        )}
        <Flex col>
          <Label htmlFor="name">Name</Label>
          <TextInput
            id="name"
            placeholder="My file"
            value={file.name}
            onChange={handleNameChange}
          />
        </Flex>
        {driver.features.description && (
          <Flex col>
            <Label htmlFor="description">Description</Label>
            <AutoTextArea
              id="description"
              placeholder="Description of my file"
              value={file.description}
              onChange={handleDescriptionChange}
            />
          </Flex>
        )}
        {driver.features.groups && (
          <Flex col>
            <Label htmlFor="tags">Tags</Label>
            <GroupSelect
              id="tags"
              for="files"
              value={file.groupIds}
              onChange={handleGroupsChange}
            />
          </Flex>
        )}
        <Flex right>
          <Button type="submit" disabled={!valid || loading}>
            Save
          </Button>
        </Flex>
      </Flex>
    </form>
  );
};

FileDetails.propTypes = {
  loading: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  showUpload: PropTypes.bool.isRequired
};

export default FileDetails;
