import React from 'react';
import PropTypes from 'prop-types';

import { Select } from '@ubisend/pulse-components';

import { useVariable } from '../../hooks/index';

const format = ({ key, name }) => {
  return { label: name, value: key };
};

const VariableTypeSelect = ({ value, ...props }) => {
  const { types } = useVariable();

  return (
    <Select
      options={types.map(format)}
      value={format(types.find(type => type.key === value))}
      {...props}
    />
  );
};

VariableTypeSelect.propTypes = {
  value: PropTypes.string
};

export default VariableTypeSelect;
