import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {
  ModalContainer,
  ModalContent,
  ModalHeader,
  ModalClose,
  Label,
  ModalPortal,
  Flex
} from '@ubisend/pulse-components';

const StyledModalContent = styled(ModalContent)`
  ${tw`w-full max-w-xl`}
  max-height: 90vh;
  overflow-y: auto;
`;

const stopPropagation = event => {
  event.stopPropagation();
  event.nativeEvent.stopImmediatePropagation();
  return false;
};

const Modal = ({ children, header, handleClose, actions, ...props }) => {
  return (
    <ModalPortal>
      <ModalContainer
        onMouseDown={stopPropagation}
        onMouseUp={stopPropagation}
        onMouseMove={stopPropagation}
        onMouseLeave={stopPropagation}
        onWheel={stopPropagation}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.1 }}>
        <StyledModalContent {...props}>
          <ModalHeader>
            <Label mb={false}>{header}</Label>
            <Flex xSpace>
              {actions && actions}
              <ModalClose onClick={handleClose} />
            </Flex>
          </ModalHeader>
          {children}
        </StyledModalContent>
      </ModalContainer>
    </ModalPortal>
  );
};

Modal.propTypes = {
  header: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
  actions: PropTypes.element
};

export default Modal;
