import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { useNotifySubscription } from '@ubisend/pulse-subscribe';
import { TypingContext, useTypingReducer } from '@ubisend/pulse-volt';

const EVENTS = {
  SUBSCRIBER_STARTED_TYPING: 'SUBSCRIBER_STARTED_TYPING',
  SUBSCRIBER_STOPPED_TYPING: 'SUBSCRIBER_STOPPED_TYPING'
};

const AgentTypingProvider = ({ children, subscriber }) => {
  const typing = useTypingReducer();

  const { dispatch, TYPES } = typing;

  const handleSubscriberStartedTyping = useCallback(
    ({ subscriber: typingSubscriber }) => {
      if (typingSubscriber.id === subscriber.id) {
        dispatch({
          type: TYPES.START_TYPING,
          message: `User #${subscriber.id} is typing...`
        });
      }
    },
    [dispatch, TYPES, subscriber.id]
  );

  const handleSubscriberStopedTyping = useCallback(
    ({ subscriber: typingSubscriber }) => {
      if (typingSubscriber.id === subscriber.id) {
        dispatch({ type: TYPES.STOP_TYPING });
      }
    },
    [dispatch, TYPES, subscriber.id]
  );

  useNotifySubscription(
    EVENTS.SUBSCRIBER_STARTED_TYPING,
    handleSubscriberStartedTyping
  );
  useNotifySubscription(
    EVENTS.SUBSCRIBER_STOPPED_TYPING,
    handleSubscriberStopedTyping
  );

  return (
    <TypingContext.Provider value={typing}>{children}</TypingContext.Provider>
  );
};

AgentTypingProvider.propTypes = {
  subscriber: PropTypes.shape({
    id: PropTypes.number.isRequired
  }).isRequired
};

export default AgentTypingProvider;
