import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useDrag, useDrop } from 'react-dnd';

import { SecondarySidebarLink, Flex, Span } from '@ubisend/pulse-components';

const DragableLink = styled(SecondarySidebarLink)`
  ${tw`border-none w-full`}
  & svg,
  & i {
    ${tw`w-5 h-5 mr-1 justify-center items-center`}
  }
`;

const SortableSidebarItem = ({
  importItem,
  section,
  handleReorder,
  checkActive
}) => {
  const containerRef = useRef(null);
  const linkRef = useRef(null);

  const [, drop] = useDrop({
    accept: 'SIDEBAR_ITEM',
    drop(droppedSection) {
      if (droppedSection.index === section.index) {
        return;
      }

      handleReorder({ from: droppedSection, to: section });
    }
  });
  const [, drag] = useDrag({
    type: 'SIDEBAR_ITEM',
    item: () => section
  });

  drag(linkRef);
  drop(containerRef);

  return (
    <Flex ref={containerRef} fat onDrop={event => event.preventDefault()}>
      <DragableLink
        ref={linkRef}
        active={checkActive(section.id)}
        key={section.id}
        to={`/knowledge-bases/${importItem.id}/sections/${section.id}/content`}>
        <Flex xSpaceSm center>
          <Span>&#9776;</Span>
          <Span>{section.content?.name}</Span>
        </Flex>
      </DragableLink>
    </Flex>
  );
};

SortableSidebarItem.propTypes = {
  importItem: PropTypes.shape({
    id: PropTypes.number
  }).isRequired,
  section: PropTypes.shape({
    id: PropTypes.number,
    index: PropTypes.number,
    content: PropTypes.shape({
      name: PropTypes.string
    })
  }).isRequired,
  handleReorder: PropTypes.func.isRequired,
  checkActive: PropTypes.func.isRequired
};

export default SortableSidebarItem;
