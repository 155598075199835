import React from 'react';
import PropTypes from 'prop-types';

import { useQrCodeReducer } from '../reducers/index';
import { QrCodeContext } from '../Contexts/index';

const QrCodeProvider = ({ value, children }) => {
  const context = useQrCodeReducer(value);

  return (
    <QrCodeContext.Provider value={context}>{children}</QrCodeContext.Provider>
  );
};

QrCodeProvider.propTypes = {
  value: PropTypes.object
};

export default QrCodeProvider;
