import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {
  ModalPortal,
  ModalContainer,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalCancel,
  ModalBody,
  Label,
  FormGroup,
  Divider,
  TextInput,
  Flex,
  Button,
  FormHeading,
  OneHalfLayout
} from '@ubisend/pulse-components';
import { CallToActionText } from '@ubisend/pulse-volt';
import {
  useConditionalReducer,
  ConditionalContext,
  Match,
  AddConditionalButton,
  Conditionals
} from '@ubisend/pulse-conditionals';

import subjects from '../../subjects/index';
import { BotProvider } from '../../Providers/index';

const defaultConditionals = {
  match: 'any',
  conditionals: [
    {
      type: {
        name: 'All the time',
        type: 'event'
      },
      condition: null,
      flipped: false,
      case_sensitive: false,
      target: '',
      subject: {
        target: null,
        type: 'message'
      }
    }
  ]
};

const CallToActionDetails = ({
  handleSubmit,
  handleCancel,
  initialDescription = '',
  initialMessage = '',
  initialConditionals = defaultConditionals
}) => {
  const [description, setDescription] = useState(initialDescription);
  const [message, setMessage] = useState(initialMessage);
  const conditionals = useConditionalReducer({
    conditionals: initialConditionals,
    subjects: subjects.conditionals.subjects
  });

  const handleDescriptionChange = event => {
    setDescription(event.target.value);
  };

  const handleMessageChange = event => {
    setMessage(event.target.value);
  };

  const onSubmit = event => {
    event.preventDefault();
    event.stopPropagation();

    handleSubmit({ description, message, ...conditionals.form });
  };

  return (
    <ModalPortal>
      <ModalContainer>
        <ModalContent
          style={{
            width: '60rem',
            maxWidth: '60rem',
            maxHeight: '40rem',
            overflowY: 'auto'
          }}>
          <ModalHeader>Configure your Call to Action</ModalHeader>
          <form onSubmit={onSubmit}>
            <ModalBody>
              <Flex xSpace>
                <OneHalfLayout>
                  <FormGroup>
                    <Label htmlFor="description">Description</Label>
                    <TextInput
                      id="description"
                      placeholder="Attract new users of the chatbot"
                      onChange={handleDescriptionChange}
                      value={description}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="message">Message</Label>
                    <TextInput
                      id="message"
                      placeholder="Hey! If you have any questions let me know"
                      onChange={handleMessageChange}
                      value={message}
                    />
                  </FormGroup>
                </OneHalfLayout>
                <OneHalfLayout>
                  <BotProvider>
                    <Flex middle center tall>
                      <CallToActionText>{message}</CallToActionText>
                    </Flex>
                  </BotProvider>
                </OneHalfLayout>
              </Flex>
              <Divider tall />
              <FormHeading>When to show your Call to Action</FormHeading>
              <ConditionalContext.Provider value={conditionals}>
                <Match />
                <Divider />
                <Conditionals labelPrefix="cta-conditionals" />
                <Flex right xSpace>
                  <AddConditionalButton />
                </Flex>
              </ConditionalContext.Provider>
            </ModalBody>
            <ModalFooter>
              <Flex right center xSpace>
                <ModalCancel type="button" onClick={handleCancel}>
                  Cancel
                </ModalCancel>
                <Button type="submit">Save</Button>
              </Flex>
            </ModalFooter>
          </form>
        </ModalContent>
      </ModalContainer>
    </ModalPortal>
  );
};

CallToActionDetails.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  initialDescription: PropTypes.string,
  initialMessage: PropTypes.string,
  initialConditionals: PropTypes.shape({
    match: PropTypes.oneOf(['any', 'all']),
    conditionals: PropTypes.array
  })
};

export default CallToActionDetails;
