import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Icon from '@ubisend/pulse-icons';

import { pseudoStyles } from '../Input/TextInput';
import { Panel, InnerPanel } from '../Panel/index';
import Divider from '../Spacing/Divider';
import Placeholder from '../Loading/Placeholder';
import Label from '../Forms/Label';
import Flex from '../Layout/Flex';

const AccordionHeader = styled.button`
  ${tw`flex justify-between items-center cursor-pointer bg-white p-4 rounded border-0`}
  margin: -1rem;
  width: calc(100% + 2rem);
  border: 1px solid transparent;
  & * {
    ${tw`cursor-pointer`}
  }
  & div {
    ${tw`flex`}
  }
  ${pseudoStyles}
`;

const AccordionContent = styled.div`
  ${tw`p-4 pt-0`}
  margin: -1rem;
`;

const ButtonIcon = styled(Icon)`
  ${tw`cursor-pointer`}
`;

const Accordion = ({
  children,
  header,
  open = false,
  actions,
  inner = true,
  disabled = false,
  loading = false,
  ...props
}) => {
  const [collapsed, setCollapsed] = useState(!open);

  const handleClick = () => {
    setCollapsed(!collapsed);
  };

  const Wrapper = inner ? InnerPanel : Panel;

  return (
    <Wrapper>
      {loading ? (
        <Placeholder />
      ) : (
        <>
          <AccordionHeader
            {...props}
            disabled={disabled}
            onClick={handleClick}
            aria-expanded={!collapsed}
            type="button">
            <Label mb={false}>{header}</Label>
            <Flex xSpace>
              {actions}
              {!disabled && (
                <ButtonIcon
                  size="1.25rem"
                  height="1.25rem"
                  width="1.25rem"
                  type={collapsed ? `cheveronDown` : `cheveronUp`}
                />
              )}
            </Flex>
          </AccordionHeader>
          {!collapsed && (
            <AccordionContent>
              <Divider fullWidth />
              {children}
            </AccordionContent>
          )}
        </>
      )}
    </Wrapper>
  );
};

Accordion.propTypes = {
  header: PropTypes.element,
  actions: PropTypes.element,
  open: PropTypes.bool,
  stretch: PropTypes.bool,
  inner: PropTypes.bool,
  disabled: PropTypes.bool,
  loading: PropTypes.bool
};

export default Accordion;
