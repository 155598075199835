import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { useQuery } from '@ubisend/pulse-hooks';
import {
  PageWrapper,
  Placeholder,
  Panel,
  Grid,
  Flex,
  Heading3,
  Button,
  UserIcon,
  NoResults,
  Pagination,
  Divider,
  usePaginationReducer
} from '@ubisend/pulse-components';

const getInitals = agent => {
  return `${agent.first_name[0]}${agent.last_name[0]}`;
};

const BigUserIcon = styled(UserIcon)`
  ${tw`w-16 h-16`}
`;

const Agents = () => {
  const pagination = usePaginationReducer({ id: 'agents' });

  const query = useQuery(['tickets/ubidesk/agents', pagination.params]);

  return (
    <PageWrapper header="Agents">
      {query.isLoading && <Placeholder />}
      {query.showNoResultsMessage === 0 && (
        <NoResults subtitle="There are currently no active agents" />
      )}
      {query.isSuccess && query.showTable && (
        <>
          <Grid columns={5}>
            {query.data.data.map(agent => (
              <Panel key={agent.id}>
                <Flex col ySpace middle center>
                  <BigUserIcon layout>{getInitals(agent)}</BigUserIcon>
                  <Flex>
                    <Heading3>{agent.name}</Heading3>
                  </Flex>
                  <Button
                    as={Link}
                    to={`/tickets/agents/${agent.id}`}
                    icon="eye">
                    View
                  </Button>
                </Flex>
              </Panel>
            ))}
          </Grid>
          {query.showPagination && (
            <>
              <Divider mbNone />
              <Pagination pagination={query.data.meta} {...pagination.props} />
            </>
          )}
        </>
      )}
    </PageWrapper>
  );
};

export default Agents;
