import React from 'react';
import PropTypes from 'prop-types';

import { Flex, Span } from '@ubisend/pulse-components';
import { TemplatingTextInput } from '@ubisend/pulse-templating';
import { GroupSelect } from '@ubisend/pulse-groups';

const CreateLiveChatTicketContent = ({ content, onChange }) => {
  const handleNameChange = name => {
    onChange({ name });
  };

  const handleDescriptionChange = description => {
    onChange({ description });
  };

  const handleTagsChange = tags => {
    onChange({ tags: tags.map(({ value }) => value) });
  };

  return (
    <Flex xSpace fat center>
      <Span>using</Span>
      <Flex col fat>
        <TemplatingTextInput
          id="name"
          placeholder="Ticket Name"
          value={content.name}
          onChange={handleNameChange}
        />
      </Flex>
      <Span>and</Span>
      <Flex col fat>
        <TemplatingTextInput
          id="description"
          placeholder="Ticket Description"
          value={content.description}
          onChange={handleDescriptionChange}
        />
      </Flex>
      <Span>and</Span>
      <GroupSelect
        id="tags"
        value={content.tags}
        onChange={handleTagsChange}
        placeholder="Tags"
        for="tickets"
      />
    </Flex>
  );
};

CreateLiveChatTicketContent.propTypes = {
  onChange: PropTypes.func.isRequired,
  content: PropTypes.shape({
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    tags: PropTypes.array.isRequired
  }).isRequired
};

export default CreateLiveChatTicketContent;
