const DEBUG_PERMISSIONS = Boolean(process.env.REACT_APP_DEBUG_PERMISSIONS);

const DEBUG_ROLES = Boolean(process.env.REACT_APP_DEBUG_PERMISSIONS);

const CLIENT_ID_HEADER = 'Pulse-Client-ID';
const CLIENT_ID_SESSION_KEY = 'Pulse-Client-ID';

export {
  DEBUG_PERMISSIONS,
  DEBUG_ROLES,
  CLIENT_ID_HEADER,
  CLIENT_ID_SESSION_KEY
};
