import axios from 'axios';

const uploadFile = ({
  endpoint,
  source_id: sourceId,
  file,
  type,
  name,
  description,
  group_ids: groupIds
}) => {
  const form = new FormData();
  form.append('source_id', sourceId);
  form.append('file[file]', file);
  form.append('file[type]', type);
  form.append('name', name);
  form.append('description', description);

  if (groupIds) {
    groupIds.forEach(id => {
      form.append('group_ids[]', id);
    });
  }

  return axios.post(endpoint, form, {
    headers: { 'Content-Type': 'multipart/form-data' }
  });
};

const updateFile = ({ id, ...params }) => {
  return axios.put(`files/${id}`, params);
};

const deleteFiles = params => {
  return axios.delete(`files`, { data: params });
};

export { uploadFile, updateFile, deleteFiles };
