import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

const GroupInput = ({ groups, defaultGroup, variable, ...props }) => {
  const { Input } = useMemo(() => {
    return groups.find(group => group.key === variable.key) || defaultGroup;
  }, [groups, variable.key, defaultGroup]);

  return <Input content={variable.content} {...props} />;
};

const group = PropTypes.shape({
  Input: PropTypes.element.isRequired
}).isRequired;

GroupInput.propTypes = {
  groups: PropTypes.arrayOf(group).isRequired,
  defaultGroup: group,
  variable: PropTypes.shape({
    key: PropTypes.string.isRequired,
    content: PropTypes.object.isRequired
  })
};

export default GroupInput;
