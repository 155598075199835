import axios from 'axios';

const deleteSubscriber = subscriberId => {
  return axios.delete(`subscribers/${subscriberId}`);
};

const exportSubscriber = subscriber_id => {
  return axios.get(`subscribers/${subscriber_id}/export`);
};

const exportTranscript = subscriber_id => {
  return axios.get(`subscribers/${subscriber_id}/export/transcript`);
};

const authoriseSubscriber = subscriberId => {
  axios.put(`/subscribers/${subscriberId}/authorise`);
};

export {
  deleteSubscriber,
  exportSubscriber,
  exportTranscript,
  authoriseSubscriber
};
