import { useCallback } from 'react';

import { useQueryClient } from '@ubisend/pulse-hooks';

const useUpdateTicketCache = () => {
  const queryClient = useQueryClient();

  const updateAllTicketsQueryCache = useCallback(
    ({ ticketId, callback }) => {
      const query = queryClient
        .getQueryCache()
        .find('tickets/ubidesk', { exact: false });

      if (!query) {
        return null;
      }

      queryClient.setQueryData(query.queryKey, oldData => {
        return {
          ...oldData,
          data: oldData.data.map(oldTicket => {
            if (oldTicket.id !== ticketId) {
              return oldTicket;
            }

            return {
              ...oldTicket,
              ...callback(oldTicket)
            };
          })
        };
      });
    },
    [queryClient]
  );

  const updateTicketCache = useCallback(
    params => {
      updateAllTicketsQueryCache(params);
    },
    [updateAllTicketsQueryCache]
  );

  return updateTicketCache;
};

export default useUpdateTicketCache;
