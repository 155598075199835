import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';

import { useMutation, useQueryClient } from '@ubisend/pulse-hooks';
import {
  Flex,
  ModalPortal,
  ModalContainer,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalClose,
  ModalBody,
  useNotification,
  Button,
  FormGroup,
  Label,
  TextInput,
  TextArea,
  LeftHalfLayout,
  RightHalfLayout
} from '@ubisend/pulse-components';
import { GroupSelect } from '@ubisend/pulse-groups';
import { PermissionFilter } from '@ubisend/pulse-auth';

import { createTicket } from '../api/index';
import SubscriberSelect from './SubscriberSelect';
import AssigneeSelect from './AssigneeSelect';

const CreateTicketModal = ({ handleClose }) => {
  const [ticket, setTicket] = useState({
    name: '',
    description: '',
    status: 'submitted',
    bot_could_answer: false,
    assignee_id: null,
    subscriber_id: null,
    groups: []
  });

  const updateTicket = object => {
    setTicket(ticket => ({ ...ticket, ...object }));
  };

  const { showSuccess } = useNotification();

  const queryClient = useQueryClient();
  const { mutate } = useMutation(createTicket, {
    onSuccess: () => {
      showSuccess('Successfully created new ticket');
      queryClient.invalidateQueries('tickets/ubidesk');
      handleClose();
    }
  });

  const handleSubmit = () => mutate(ticket);

  const handleNameChange = event => {
    const name = event.target.value;
    updateTicket({ name });
  };

  const handleDescriptionChange = event => {
    const description = event.target.value;
    updateTicket({ description });
  };

  const handleGroupsChange = (groups = null) => {
    updateTicket({ groups: groups ? groups.map(group => group.value) : [] });
  };

  const handleAssigneeChange = option => {
    updateTicket({ assignee_id: option ? option.value : null });
  };

  const handleSubscriberChange = option => {
    updateTicket({ subscriber_id: option ? option.value : null });
  };

  const valid = useMemo(() => {
    return Boolean(ticket.name);
  }, [ticket]);

  return (
    <ModalPortal>
      <ModalContainer>
        <ModalContent style={{ width: '100%', maxWidth: '62rem' }}>
          <ModalHeader>
            Create New Ticket
            <ModalClose onClick={handleClose} />
          </ModalHeader>
          <ModalBody>
            <Flex>
              <LeftHalfLayout>
                <Label htmlFor="description">Description</Label>
                <TextArea
                  id="description"
                  value={ticket.description}
                  onChange={handleDescriptionChange}
                  placeholder="A description of the problem."
                />
              </LeftHalfLayout>
              <RightHalfLayout>
                <FormGroup>
                  <Label htmlFor="summary">Summary</Label>
                  <TextInput
                    id="summary"
                    placeholder="I'm having an issue"
                    value={ticket.name}
                    onChange={handleNameChange}
                  />
                </FormGroup>
                <FormGroup>
                  <Label htmlFor="tags">Tags</Label>
                  <GroupSelect
                    id="tags"
                    value={ticket.groups}
                    onChange={handleGroupsChange}
                    placeholder="HR"
                    for="tickets"
                  />
                </FormGroup>
                <PermissionFilter can="view agents">
                  <FormGroup>
                    <Label htmlFor="assignees">Assigned To</Label>
                    <AssigneeSelect
                      id="assignees"
                      value={ticket.assignee_id}
                      onChange={handleAssigneeChange}
                    />
                  </FormGroup>
                </PermissionFilter>
                <div>
                  <Label htmlFor="subscribers">Subscriber</Label>
                  <SubscriberSelect
                    id="subscribers"
                    value={ticket.subscriber_id}
                    onChange={handleSubscriberChange}
                  />
                </div>
              </RightHalfLayout>
            </Flex>
          </ModalBody>
          <ModalFooter>
            <Flex xSpace>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
              <Button disabled={!valid} onClick={handleSubmit} icon="save">
                Save
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </ModalContainer>
    </ModalPortal>
  );
};

CreateTicketModal.propTypes = {
  handleClose: PropTypes.func.isRequired
};

export default CreateTicketModal;
