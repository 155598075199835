import React from 'react';
import PropTypes from 'prop-types';

import { PreviewCondition } from '@ubisend/pulse-components';
import { ConditionPreview } from '@ubisend/pulse-conditionals';

const VariablePreview = ({ prefix = 'When', ...props }) => (
  <p>
    {prefix}
    {props.flipped === 1 && ' not'}
    {` variable ${props.subject.target}`}
    <PreviewCondition>{props.type.name}</PreviewCondition>
    <ConditionPreview {...props} />
  </p>
);

VariablePreview.propTypes = {
  prefix: PropTypes.string,
  flipped: PropTypes.number,
  type: PropTypes.shape({ type: PropTypes.string, name: PropTypes.string }),
  subject: PropTypes.shape({
    target: PropTypes.string.isRequired
  }).isRequired
};

export default VariablePreview;
