import React from 'react';
import PropTypes from 'prop-types';

import {
  Label,
  Flex,
  Select,
  formatSelectOption
} from '@ubisend/pulse-components';
import { useLanguages } from '@ubisend/pulse-hooks';

import { useLanguage } from '../../hooks/index';

const LanguageSelect = ({ value, ...props }) => {
  const { languages } = useLanguages();

  return (
    <Select
      aria-label="language-select"
      isLoading={!languages}
      options={languages ? languages.map(formatSelectOption) : []}
      value={
        value && languages
          ? formatSelectOption(languages.find(({ id }) => id === value))
          : null
      }
      {...props}
    />
  );
};

LanguageSelect.propTypes = {
  value: PropTypes.number
};

const Settings = () => {
  const { language, setLanguage } = useLanguage();

  const handleLanguageChange = option => {
    setLanguage(option.value);
  };

  return (
    <Flex col pad>
      <Flex col ySpace>
        <Label htmlFor="language">Preview language</Label>
        <LanguageSelect
          id="language"
          value={language}
          onChange={handleLanguageChange}
        />
      </Flex>
    </Flex>
  );
};

export default Settings;
