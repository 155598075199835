import React from 'react';
import PropTypes from 'prop-types';

import {
  Flex,
  useNotification,
  Panel,
  Button,
  InnerPanel,
  Label,
  SubHeader
} from '@ubisend/pulse-components';

import { removeSettings } from '../api/index';
import FacebookIcon from './FacebookIcon';

const FacebookSettings = ({ settings, handleUnlinkPage }) => {
  const { showSuccess, showError } = useNotification();

  const handleDeleteSettings = async () => {
    const response = await removeSettings();

    if (response?.status === 202) {
      showSuccess(`Successfully unlinked Facebook page`);
      handleUnlinkPage();
    } else {
      showError(`Something went wrong whilst unlinking a Facebook page`);
    }
  };

  return (
    <Panel header="Update Facebook Messenger settings">
      <Flex col ySpace mt>
        <InnerPanel>
          <Flex center>
            <FacebookIcon style={{ marginRight: '1rem' }} size={48} />
            <Flex col ySpace>
              <Label>Connected to</Label>
              <SubHeader>{settings.page_name}</SubHeader>
            </Flex>
          </Flex>
        </InnerPanel>
        <Flex>
          <Button colour="danger" onClick={handleDeleteSettings}>
            Remove connection
          </Button>
        </Flex>
      </Flex>
    </Panel>
  );
};

FacebookSettings.propTypes = {
  settings: PropTypes.shape({
    page_name: PropTypes.string.isRequired
  }).isRequired,
  handleUnlinkPage: PropTypes.func.isRequired
};

export default FacebookSettings;
