import React from 'react';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import {
  Heading2,
  Label,
  LeftHalfLayout,
  FormGroup,
  RightHalfLayout,
  Paragraph,
  Flex,
  Panel,
  TextInput,
  Button,
  Span
} from '@ubisend/pulse-components';
import { TopSection } from '@ubisend/pulse-tickets';
import { PermissionFilter } from '@ubisend/pulse-auth';

import JsonPreview from '../JsonPreview';
import { getName } from '../LogName';
import LogLevel from '../LogLevel';

const SheetContent = ({ created_at, description, content, level, type }) => (
  <Panel>
    <Flex col mb>
      <TopSection center>
        <LogLevel level={level} />
        <Heading2>{getName({ level, type })}</Heading2>
      </TopSection>
      <Label secondary>Logged {dayjs(created_at).from(dayjs())}</Label>
    </Flex>
    <Flex>
      <LeftHalfLayout>
        <Label>Description</Label>
        <Paragraph>{description}</Paragraph>
      </LeftHalfLayout>
      <RightHalfLayout>
        <FormGroup>
          <Label>Spreadsheet</Label>
          <Flex xSpace>
            <TextInput value={content.spreadsheet.name} disabled />
            <PermissionFilter can="view spreadsheets">
              <Button
                as={Link}
                variant="secondary"
                icon="eye"
                to={`/integrations`}>
                View
              </Button>
            </PermissionFilter>
          </Flex>
        </FormGroup>
        <FormGroup>
          <Label>Lookup</Label>
          <Flex xSpace>
            <TextInput value={content.lookup.name} disabled />
            <PermissionFilter can="view spreadsheets">
              <Button
                as={Link}
                variant="secondary"
                icon="eye"
                to={`/integrations`}>
                View
              </Button>
            </PermissionFilter>
          </Flex>
        </FormGroup>
        <div>
          <Label>Data</Label>
          {content.data ? (
            <JsonPreview value={content.data} />
          ) : (
            <Span tinyText>No data returned</Span>
          )}
        </div>
      </RightHalfLayout>
    </Flex>
  </Panel>
);

SheetContent.propTypes = {
  created_at: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  content: PropTypes.shape({
    spreadsheet: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired
    }),
    lookup: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired
    }),
    data: PropTypes.string
  }),
  level: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired
};

export default SheetContent;
