import { useCallback } from 'react';

import { useNotifySubscription } from '@ubisend/pulse-subscribe';

import { useAuth, useResubscribe } from '../hooks/index';

const EVENTS = {
  SUBSCRIBER_LOGGED_IN: 'SUBSCRIBER_LOGGED_IN',
  SUBSCRIBER_LOGGED_OUT: 'SUBSCRIBER_LOGGED_OUT'
};

const AuthChanger = () => {
  const { setToken } = useAuth();
  const { resubscribe } = useResubscribe();

  const handleSubscriberLoggingIn = useCallback(
    ({ token }) => {
      setToken(token);
    },
    [setToken]
  );
  const handleSubscriberLoggingOut = useCallback(() => {
    resubscribe();
  }, [resubscribe]);

  useNotifySubscription(EVENTS.SUBSCRIBER_LOGGED_IN, handleSubscriberLoggingIn);

  useNotifySubscription(
    EVENTS.SUBSCRIBER_LOGGED_OUT,
    handleSubscriberLoggingOut
  );

  return null;
};

export default AuthChanger;
