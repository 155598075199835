import styled from 'styled-components';

import { flex, spacing, position, display } from '../styles';

const Box = styled.div`
  ${tw`py-8 px-8`}
  ${props => {
    return (
      props.border === 'dashed' && tw`border-grey-medium border-dashed border`
    );
  }}
  ${flex}
  ${spacing}
  ${position}
  ${display}
`;

export default Box;
