import React, { useState } from 'react';
import { TimePicker } from '@material-ui/pickers';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import {
  Container,
  LeftSection,
  ComposerButton,
  Airplane,
  Input,
  Label,
  labelAnimations
} from '../Custom/MessageComposer';
import { useComposer, useDelivery } from '../../../../../hooks/index';
import { DateProvider } from '../../../../../Providers/index';
import { DateWrapper } from '../../../../../Components/index';

const TimeComposer = () => {
  const [open, setOpen] = useState(false);

  const { t } = useTranslation('full_page');

  const { sendMessage } = useDelivery();
  const { reply, setReply } = useComposer();

  const handleInputClick = () => setOpen(open => !open);

  const handleChange = date => {
    setReply(dayjs(date).format('HH:mm'));
  };

  const handleNewReply = () => {
    if (!reply) {
      return;
    }

    sendMessage(reply);
    setReply('');
  };

  return (
    <DateProvider primary="#FFFFFF">
      <DateWrapper>
        {open && (
          <TimePicker
            open
            variant="dialog"
            onAccept={date => {
              handleChange(date);
              setOpen(false);
            }}
            onClose={() => setOpen(false)}
            TextFieldComponent={() => null}
            onChange={handleChange}
            value={reply}
            maxDate={dayjs().add('10', 'year').toDate()}
          />
        )}
        <Container>
          <LeftSection htmlFor="time-composer">
            <Label
              htmlFor="time-composer"
              initial="expanded"
              variants={labelAnimations}
              animate={open || reply ? 'minimised' : 'expanded'}>
              {t('time_composer_label')}
            </Label>
            <Input
              id="time-composer"
              readOnly
              value={reply}
              onClick={handleInputClick}
            />
          </LeftSection>
          <ComposerButton
            aria-label={t('send_button_label')}
            onClick={handleNewReply}>
            <Airplane />
          </ComposerButton>
        </Container>
      </DateWrapper>
    </DateProvider>
  );
};

export default TimeComposer;
