const getParam = (name, url = window.location.href) => {
  return new URL(url).searchParams.get(name);
};

const getAllParams = url => {
  return [...new URL(url).searchParams.entries()].map(([name, value]) => {
    return { name, value };
  });
};

export { getParam, getAllParams };
