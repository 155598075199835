import React from 'react';
import PropTypes from 'prop-types';

import { Select } from '@ubisend/pulse-components';
import { useLanguages } from '@ubisend/pulse-hooks';

const format = language => ({ value: language.id, label: language.name });

const LanguageSelect = ({ value, ...props }) => {
  const { languages } = useLanguages();

  return (
    <Select
      aria-label="language-select"
      isLoading={!languages}
      options={languages ? languages.map(format) : []}
      value={
        value && languages
          ? format(languages.find(({ id }) => id === value))
          : null
      }
      {...props}
    />
  );
};

LanguageSelect.propTypes = {
  value: PropTypes.number
};

export default LanguageSelect;
