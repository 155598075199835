import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { motion } from '@ubisend/framer-motion';

import useTheme from '../../hooks/useTheme';

const Container = styled(motion.div)`
  ${tw`inline-flex items-center rounded-sm cursor-pointer rounded-full relative shadow-inner flex-no-shrink`}
  width:3.25em;
  height: 1.75rem;
  background: ${props => props.theme.grey};
`;

const Dot = styled(motion.div)`
  ${tw` bg-white rounded-full absolute shadow`}
  height: 1.25rem;
  width: 1.25rem;
  margin: 0.25rem;
`;

const MiniToggle = ({ handleToggleChange, on, title, disabled, ...props }) => {
  const theme = useTheme();
  return (
    <Container
      animate={{
        opacity: disabled ? 0.5 : 1,
        cursor: disabled ? 'not-allowed' : 'pointer',
        backgroundColor: on ? theme.positive : theme.grey
      }}
      whileHover={!disabled && { opacity: 0.8 }}
      {...props}
      onClick={!disabled ? handleToggleChange : undefined}>
      <Dot
        // layout // This looks weird currently due to a flickering scroll bar
        aria-label={`${title}-toggle-${on ? 'off' : 'on'}`}
        style={on ? { right: 0 } : { left: 0 }}
      />
    </Container>
  );
};

MiniToggle.propTypes = {
  handleToggleChange: PropTypes.func.isRequired,
  on: PropTypes.bool,
  title: PropTypes.string,
  disabled: PropTypes.bool
};

export default MiniToggle;
