import React from 'react';
import PropTypes from 'prop-types';

import { useQuery } from '@ubisend/pulse-hooks';
import { Select, formatSelectOption } from '@ubisend/pulse-components';

const SubscriberRetentionTypeSelect = ({ value, ...props }) => {
  const { data, isLoading, isSuccess } = useQuery('subscriber-retention-types');

  return (
    <Select
      isLoading={isLoading}
      options={isSuccess ? data.data.map(formatSelectOption) : []}
      value={
        isSuccess && value
          ? formatSelectOption(data.data.find(type => type.id === value))
          : null
      }
      {...props}
    />
  );
};

SubscriberRetentionTypeSelect.propTypes = {
  value: PropTypes.number.isRequired
};

export default SubscriberRetentionTypeSelect;
