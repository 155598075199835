import baseAxios from 'axios';

import useMutation from './useMutation';

const uploadS3File = async (options, file) => {
  await baseAxios.create().put(options.url, file, {
    headers: options.headers
  });

  return options.path;
};

const uploadPulseFile = async (url, file) => {
  const form = new FormData();

  form.append('file', file);

  const { data } = await baseAxios.create().post(url, form, {
    headers: { 'Content-Type': 'multipart/form-data' }
  });

  return data;
};

const uploadFile = async (authedAxios, file) => {
  const { data: options } = await authedAxios.get('files/signed-url', {
    params: { name: file.name }
  });

  let path;

  if (process.env.NODE_ENV === 'development') {
    path = await uploadPulseFile(options, file);
  } else {
    path = await uploadS3File(options, file);
  }

  return { path, file };
};

const uploadFiles = (authedAxios, fileList) => {
  return Promise.all([...fileList].map(file => uploadFile(authedAxios, file)));
};

const useUploadFiles = options => {
  const mutation = useMutation(uploadFiles, options);

  return mutation;
};

export default useUploadFiles;
