import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { motion } from '@ubisend/framer-motion';

import { useDelivery } from '../../../hooks/index';
import ActionButton from './ActionButton/index';

const ActionsContainer = styled(motion.div)`
  ${tw`flex flex-col mx-auto mt-8 max-w-md w-full`}
  & > * {
    ${tw`md:mb-4 mb-1`}
  }
  & > *:last-child {
    ${tw`mb-0`}
  }
`;

const containerAnimation = {
  animate: {
    transition: {
      delayChildren: 0.5,
      staggerChildren: 0.1
    }
  },
  exit: { opacity: 0 }
};

const buttonAnimation = {
  initial: { opacity: 0, y: 25, scale: 1 },
  animate: {
    opacity: 1,
    y: 0,
    scale: 1,
    transition: {
      ease: 'circOut',
      duration: 0.6
    }
  },
  exit: { opacity: 0 }
};

const Actions = ({ responses }) => {
  const { sendMessage } = useDelivery();

  return (
    <ActionsContainer
      initial="initial"
      animate="animate"
      exit="exit"
      variants={containerAnimation}>
      {responses.map((response, key) => (
        <ActionButton
          key={key}
          response={response}
          handleButtonClick={sendMessage}
          variants={buttonAnimation}
        />
      ))}
    </ActionsContainer>
  );
};

Actions.propTypes = {
  responses: PropTypes.array
};

export default Actions;
