import CarouselInput from './CarouselInput';

const carousel = {
  id: 'carousel',
  name: 'Carousel',
  Input: CarouselInput,
  content: {
    items: [
      {
        title: '',
        subtitle: '',
        image: 'http://placehold.it/400x200',
        image_description: '',
        url: '',
        responses: []
      }
    ]
  },
  valid: content => {
    return (
      content.items.length > 0 &&
      content.items.every(content => {
        return (
          content.title.length > 0 &&
          content.subtitle.length > 0 &&
          content.image.length > 0 &&
          content.url.length > 0 &&
          content.responses.length > 0 &&
          // TODO: Validate per button type
          content.responses.every(({ label, action }) => label && action)
        );
      })
    );
  },
  snippet: `{
    "type": "carousel",
    "content": {
      "items": [
        {
          "title": "",
          "subtitle": "",
          "image": "http://placehold.it/400x200",
          "image_description": "",
          "url": "",
          "responses": [
            {"type": "postback", "label": "", "action": ""}
          ]
        }
      ]
    }
}`
};

export default carousel;
