import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from 'react-query';

import { Select } from '@ubisend/pulse-components';

const defaultComposerSelectGroups = [
  { label: 'Built In', options: [] },
  { label: 'Custom', options: [] }
];

const formatComposerSelectOption = composer => ({
  label: composer.name,
  value: composer
});

const groupComposerSelectOptions = (composers, format) => {
  return composers.reduce(([builtIn, custom], composer) => {
    return [
      {
        label: 'Built In',
        options: composer.is_custom
          ? builtIn.options
          : builtIn.options.concat(format(composer))
      },
      {
        label: 'Custom',
        options: composer.is_custom
          ? custom.options.concat(format(composer))
          : custom.options
      }
    ];
  }, defaultComposerSelectGroups);
};

const ComposerSelect = ({ value, onChange, ...props }) => {
  const { data: composers } = useQuery('composers');

  const defaultComposer = composers?.data.find(
    composer => composer.is_client_default
  );

  // Handle default state. Default the value of the composer if no value is
  // passed.
  useEffect(() => {
    if (!value && defaultComposer) {
      onChange(formatComposerSelectOption(defaultComposer));
    }
  }, [value, defaultComposer, onChange]);

  // Handle default display value. If no value passed default display value
  // of the composer.
  const wrappedValue = value || defaultComposer;

  return (
    <Select
      {...props}
      isLoading={!composers}
      onChange={onChange}
      options={
        composers
          ? groupComposerSelectOptions(
              composers.data,
              formatComposerSelectOption
            )
          : []
      }
      value={
        composers &&
        formatComposerSelectOption(
          composers.data.find(composer => composer.id === wrappedValue.id)
        )
      }
    />
  );
};

ComposerSelect.propTypes = {
  value: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    is_default: PropTypes.bool.isRequired,
    is_custom: PropTypes.bool.isRequired
  }),
  onChange: PropTypes.func.isRequired
};
ComposerSelect.defaultComposerSelectGroups = defaultComposerSelectGroups;
ComposerSelect.formatComposerSelectOption = formatComposerSelectOption;
ComposerSelect.groupComposerSelectOptions = groupComposerSelectOptions;

export default ComposerSelect;
