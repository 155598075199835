import CreateLiveChatTicketContent from './CreateLiveChatTicketContent';

const createLiveChatTicket = {
  name: 'Create live chat ticket',
  Content: CreateLiveChatTicketContent,
  setInitialContent: (content = {}) => {
    return {
      name: content.name || '',
      description: content.description || '',
      tags: content.tags || []
    };
  },
  valid: content => {
    return Boolean(content.name && content.description);
  }
};

export default createLiveChatTicket;
