import React from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';

import {
  Select,
  Flex,
  Button,
  TimePicker,
  Span,
  Divider
} from '@ubisend/pulse-components';
import Icon from '@ubisend/pulse-icons';

import { useActiveHoursReducer } from '../reducers/index';

const DAYS = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday'
];

const capitaliseFirstLetter = string => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const DaySelect = ({ value, ...props }) => {
  return (
    <Select
      options={DAYS.map(DAY => ({
        label: capitaliseFirstLetter(DAY),
        value: DAY
      }))}
      value={value ? { label: capitaliseFirstLetter(value), value } : null}
      {...props}
    />
  );
};

DaySelect.propTypes = {
  value: PropTypes.oneOf(DAYS)
};

const ActiveHoursDetails = ({
  initialActiveHours,
  onSubmit,
  loading,
  disabled
}) => {
  const activeHours = useActiveHoursReducer(initialActiveHours);

  const handleOpenDayChange = id => option => {
    activeHours.dispatch({
      type: activeHours.TYPES.UPDATE_OPEN_DAY,
      id,
      day: option.value
    });
  };

  const handleOpenTimeChange = id => ([time]) => {
    activeHours.dispatch({
      type: activeHours.TYPES.UPDATE_OPEN_TIME,
      id,
      time: dayjs(time).format('HH:mm:ss')
    });
  };

  const handleCloseDayChange = id => option => {
    activeHours.dispatch({
      type: activeHours.TYPES.UPDATE_CLOSE_DAY,
      id,
      day: option.value
    });
  };

  const handleCloseTimeChange = id => ([time]) => {
    activeHours.dispatch({
      type: activeHours.TYPES.UPDATE_CLOSE_TIME,
      id,
      time: dayjs(time).format('HH:mm:ss')
    });
  };

  const handleNewHours = () => {
    activeHours.dispatch({ type: activeHours.TYPES.ADD_HOURS });
  };

  const handleHoursDelete = id => () => {
    activeHours.dispatch({ type: activeHours.TYPES.DELETE_HOURS, id });
  };

  const handleSubmit = event => {
    event.preventDefault();

    if (disabled || !activeHours.valid) {
      return;
    }

    onSubmit(activeHours.form);
  };

  return (
    <form onSubmit={handleSubmit}>
      <Flex col>
        {activeHours.activeHours.length === 0 && (
          <Span light mb>
            No working hours setup
          </Span>
        )}
        {activeHours.activeHours.length > 0 && (
          <Flex col>
            {activeHours.activeHours.map(hours => (
              <React.Fragment key={hours.id}>
                <Flex xSpaceSm middle center>
                  <Flex col fat ySpaceSm>
                    <DaySelect
                      aria-label="Opening day"
                      value={hours.open_day}
                      onChange={handleOpenDayChange(hours.id)}
                      disabled={disabled}
                    />
                    <TimePicker
                      aria-label="Opening time"
                      value={hours.open_time}
                      onChange={handleOpenTimeChange(hours.id)}
                      disabled={disabled}
                    />
                  </Flex>
                  <Icon
                    type="cheveronRight"
                    size="1.5rem"
                    height="1.5rem"
                    width="1.5rem"
                  />
                  <Flex col fat mr ySpaceSm>
                    <DaySelect
                      aria-label="Closing day"
                      value={hours.close_day}
                      onChange={handleCloseDayChange(hours.id)}
                      disabled={disabled}
                    />
                    <TimePicker
                      aria-label="Closing time"
                      value={hours.close_time}
                      onChange={handleCloseTimeChange(hours.id)}
                      disabled={disabled}
                    />
                  </Flex>
                  <Button
                    aria-label="Delete working hours"
                    icon="trash"
                    colour="danger"
                    onClick={handleHoursDelete(hours.id)}
                  />
                </Flex>
                <Divider fullWidth />
              </React.Fragment>
            ))}
          </Flex>
        )}
        <Flex xSpace between>
          <Button
            icon="save"
            type="submit"
            loading={loading}
            disabled={disabled || !activeHours.valid}>
            Save
          </Button>
          <Button icon="plus" onClick={handleNewHours} disabled={disabled}>
            Add
          </Button>
        </Flex>
      </Flex>
    </form>
  );
};

ActiveHoursDetails.propTypes = {
  initialActiveHours: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      open_day: PropTypes.oneOf(DAYS).isRequired,
      open_time: PropTypes.string.isRequired,
      close_day: PropTypes.oneOf(DAYS).isRequired,
      close_time: PropTypes.string.isRequired
    })
  ),
  onSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired
};

export default ActiveHoursDetails;
