import React, { useState } from 'react';

import { useQuery, useMutation, useQueryClient } from '@ubisend/pulse-hooks';
import { AnimateSharedLayout } from '@ubisend/framer-motion';
import {
  PageWrapper,
  Placeholder,
  Flex,
  ButtonSelect,
  useNotification,
  NoSubcriberGraphic,
  Heading3,
  TabButton
} from '@ubisend/pulse-components';
import { PermissionFilter } from '@ubisend/pulse-auth';

import {
  Comments,
  TicketDetails,
  ForwardTicketButton,
  SubscriberDetails,
  DetailsPanel,
  MainSection,
  NoSubscriber,
  Activity
} from '../Components/index';
import { updateTicket } from '../api/index';
import { statuses } from '../Components/AgentDetails';
import { getTicket } from '../utilities/index';

const colours = {
  submitted: 'danger',
  in_progress: 'warning',
  resolved: 'positive'
};

const getEvent = (status, previousStatus) => {
  if (!previousStatus) {
    return 'Ticket was created';
  }

  let changeText = `Ticket was changed from ${
    statuses[previousStatus.status]
  } to ${statuses[status.status]}`;

  if (status.agent) {
    changeText = `${changeText} by ${status.agent.name}`;
  }

  if (status.assignee) {
    changeText = `${changeText} (while being assigned to ${status.assignee.name})`;
  }

  return changeText;
};

const Ticket = ({ match }) => {
  const [tab, setTab] = useState('COMMENTS');

  const { showSuccess } = useNotification();

  const queryClient = useQueryClient();
  const query = useQuery(`tickets/ubidesk/${match.params.id}`);
  const mutation = useMutation(updateTicket, {
    onSuccess: () => {
      showSuccess('Successfully updated ticket status');
      queryClient.invalidateQueries(`tickets/ubidesk/${match.params.id}`);
    }
  });

  const handleUpdateStatus = ({ value: status }) => {
    mutation.mutate({
      ...getTicket(query.data.data),
      status
    });
  };

  const statusOptions = [
    { value: 'submitted', label: 'Submitted' },
    { value: 'in_progress', label: 'In Progress' },
    { value: 'resolved', label: 'Resolved' }
  ];

  return (
    <Flex>
      <MainSection>
        <PageWrapper
          header="Ticket Details"
          actions={
            <Flex xSpace center>
              <ButtonSelect
                placeholder="loading"
                isLoading={query.isLoading || mutation.isLoading}
                colour={colours[query.data?.data?.status] || 'primary'}
                value={statusOptions.find(
                  _ => _.value === query.data?.data?.status
                )}
                options={statusOptions}
                onChange={handleUpdateStatus}
              />
              <PermissionFilter can="view agents">
                <ForwardTicketButton id={match.params.id}>
                  Forward
                </ForwardTicketButton>
              </PermissionFilter>
            </Flex>
          }>
          <Flex ySpace col>
            <TicketDetails ticket={query.data?.data} />
            <Flex center xSpace>
              <AnimateSharedLayout>
                <TabButton
                  active={tab === 'COMMENTS'}
                  onClick={() => setTab('COMMENTS')}>
                  Comments
                </TabButton>
                <TabButton
                  active={tab === 'ACTIVITY'}
                  onClick={() => setTab('ACTIVITY')}>
                  Activity
                </TabButton>
              </AnimateSharedLayout>
            </Flex>
            {tab === 'COMMENTS' && (
              <Comments query={`tickets/ubidesk/${match.params.id}/comments`} />
            )}
            {tab === 'ACTIVITY' && (
              <Activity
                query={`tickets/ubidesk/${match.params.id}/statuses`}
                getEvent={getEvent}
              />
            )}
          </Flex>
        </PageWrapper>
      </MainSection>
      <DetailsPanel divider header="Subscriber Details">
        {query.isLoading && <Placeholder />}
        {query.isSuccess && query.data.data.subscriber && (
          <SubscriberDetails
            subscriber={query.data.data.subscriber}
            ticket={query.data?.data}
          />
        )}
        {query.isSuccess && !query.data.data.subscriber && (
          <NoSubscriber col center ySpace middle>
            <NoSubcriberGraphic />
            <Heading3>No Subscriber attached to ticket</Heading3>
          </NoSubscriber>
        )}
      </DetailsPanel>
    </Flex>
  );
};

export default Ticket;
