import React, { useState } from 'react';
import styled from 'styled-components';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { motion } from '@ubisend/framer-motion';
import { pseudoStyles, Flex } from '@ubisend/pulse-components';

import {
  useBot,
  useNotifications,
  useBelowScreenSize
} from '../../../hooks/index';
import {
  TicketFilter,
  BotLogo,
  UnreadNotifications
} from '../../../Components/index';

const Clock = () => (
  <svg
    fill="currentColor"
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 00.293.707l2.828 2.829a1 1 0 101.415-1.415L11 9.586V6z"
      clipRule="evenodd"
    />
  </svg>
);

const Cog = props => (
  <svg
    fill="currentColor"
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      fillRule="evenodd"
      d="M11.49 3.17c-.38-1.56-2.6-1.56-2.98 0a1.532 1.532 0 01-2.286.948c-1.372-.836-2.942.734-2.106 2.106.54.886.061 2.042-.947 2.287-1.561.379-1.561 2.6 0 2.978a1.532 1.532 0 01.947 2.287c-.836 1.372.734 2.942 2.106 2.106a1.532 1.532 0 012.287.947c.379 1.561 2.6 1.561 2.978 0a1.533 1.533 0 012.287-.947c1.372.836 2.942-.734 2.106-2.106a1.533 1.533 0 01.947-2.287c1.561-.379 1.561-2.6 0-2.978a1.532 1.532 0 01-.947-2.287c.836-1.372-.734-2.942-2.106-2.106a1.532 1.532 0 01-2.287-.947zM10 13a3 3 0 100-6 3 3 0 000 6z"
      clipRule="evenodd"
    />
  </svg>
);

const Bell = props => (
  <svg
    fill="currentColor"
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path d="M10 2a6 6 0 00-6 6v3.586l-.707.707A1 1 0 004 14h12a1 1 0 00.707-1.707L16 11.586V8a6 6 0 00-6-6zM10 18a3 3 0 01-3-3h6a3 3 0 01-3 3z" />
  </svg>
);

const Chat = props => (
  <svg
    fill="currentColor"
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path d="M2 5a2 2 0 012-2h7a2 2 0 012 2v4a2 2 0 01-2 2H9l-3 3v-3H4a2 2 0 01-2-2V5z" />
    <path d="M15 7v2a4 4 0 01-4 4H9.828l-1.766 1.767c.28.149.599.233.938.233h2l3 3v-3h2a2 2 0 002-2V9a2 2 0 00-2-2h-1z" />
  </svg>
);

const Ticket = props => (
  <svg
    fill="currentColor"
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path d="M2 6a2 2 0 012-2h12a2 2 0 012 2v2a2 2 0 100 4v2a2 2 0 01-2 2H4a2 2 0 01-2-2v-2a2 2 0 100-4V6z" />
  </svg>
);

const NavButton = styled(motion.button)`
  ${tw`flex items-center py-2 px-2 md:pl-2 md:pr-4 ml-3 text-white outline-none border-none relative cursor-pointer`}
  border-radius: 1rem;
  background: ${props => {
    return props.active ? 'rgba(255,255,255,0.2)' : 'transparent';
  }};
  transition: background 0.2s ease-out;
  & svg {
    ${tw`w-4 h-4`}
  }
  & > span {
    ${tw`hidden md:block uppercase font-poppins font-semibold text-xs md:ml-2`}
  }
  ${pseudoStyles}
`;

NavButton.defaultProps = {
  colour: 'white'
};

const Container = styled(motion.div)`
  ${tw`flex p-4 justify-between fixed pin-t lg:relative w-full z-10 flex-shrink`}
  box-sizing: border-box;
  border-bottom: 1px solid rgba(255, 255, 255, 0.125);
  & img {
    ${tw`h-8`}
  }
`;

const WindowHeader = () => {
  const [showNumber, setShowNumber] = useState(false);
  const { unreadCount } = useNotifications();

  const { t } = useTranslation(['bots', 'full_page']);

  const { logo, showLogo, logoLink } = useBot();
  const location = useLocation();
  const isMobile = useBelowScreenSize();

  const history = useHistory();

  const toggleMessages = () => {
    if (location.pathname !== '/window/body/messages') {
      history.push('/window/body/messages');
    }
  };

  const toggleNotifications = () => {
    if (location.pathname !== '/window/body/notifications') {
      history.push('/window/body/notifications');
    }
  };

  const toggleTickets = () => {
    if (location.pathname !== '/window/body/tickets') {
      history.push('/window/body/tickets');
    }
  };

  const toggleHistory = () => {
    if (location.pathname !== '/window/body/history') {
      history.push('/window/body/history');
    }
  };

  const toggleSettings = () => {
    if (location.pathname !== '/window/body/settings') {
      history.push('/window/body/settings');
    }
  };

  const isActive = path => location.pathname.includes(path);

  return (
    <>
      <Container>
        <BotLogo showLogo={showLogo} logoLink={logoLink} logo={logo} />
        <Flex>
          <TicketFilter>
            <NavButton
              aria-label={t('tickets_header', { ns: 'bots' })}
              active={isActive('/tickets')}
              layout
              onClick={toggleTickets}>
              <Ticket />
              <span>{t('tickets_header', { ns: 'bots' })}</span>
            </NavButton>
          </TicketFilter>
          <Flex relative>
            {unreadCount > 0 && (
              <Flex
                absolute
                style={
                  isMobile
                    ? { right: '0.25rem', top: '0.5rem' }
                    : { left: '1rem', top: '0.5rem' }
                }>
                <UnreadNotifications scale={1} showNumber={showNumber}>
                  {unreadCount > 9 ? '9+' : unreadCount}
                </UnreadNotifications>
              </Flex>
            )}
            <NavButton
              aria-label={t('notifications_header', { ns: 'bots' })}
              layout
              active={isActive('/notifications')}
              onClick={toggleNotifications}
              onMouseOver={() => setShowNumber(true)}
              onMouseLeave={() => setShowNumber(false)}>
              <Bell />
              <span>{t('notifications_header', { ns: 'bots' })}</span>
            </NavButton>
          </Flex>
          <NavButton
            aria-label={t('messages_header', { ns: 'full_page' })}
            layout
            active={isActive('/history')}
            onClick={toggleHistory}>
            <Clock />
            <span>{t('messages_header', { ns: 'full_page' })}</span>
          </NavButton>
          <NavButton
            aria-label={t('chat_header', { ns: 'full_page' })}
            layout
            active={isActive('/messages')}
            onClick={toggleMessages}>
            <Chat />
            <span>{t('chat_header', { ns: 'full_page' })}</span>
          </NavButton>
          <NavButton
            aria-label={t('settings_header', { ns: 'bots' })}
            layout
            active={isActive('/settings')}
            style={{ paddingRight: '0.5rem' }}
            onClick={toggleSettings}>
            <Cog />
            <span>{t('settings_header', { ns: 'bots' })}</span>
          </NavButton>
        </Flex>
      </Container>
    </>
  );
};

export default WindowHeader;
