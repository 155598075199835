import React, { useState } from 'react';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';

import { useQuery, useMutation, useQueryClient } from '@ubisend/pulse-hooks';
import { AnimatePresence } from '@ubisend/framer-motion';
import {
  PageWrapper,
  PanelSlider,
  Flex,
  useNotification,
  useModal,
  StretchPanel,
  Table,
  TableCell,
  TableRow,
  TableHead,
  TableBody,
  TableActions,
  Pagination,
  Span,
  Button,
  NumberFormatter,
  usePaginationReducer,
  OrderableTableRow,
  useOrderableTableReducer
} from '@ubisend/pulse-components';
import { ImageSelect } from '@ubisend/pulse-files';

import {
  deleteClient as deleteClientApi,
  updateClientLogo as updateClientLogoApi
} from '../api/index';
import {
  EditClient,
  CreateClient,
  ClientCard,
  LicenseTotal,
  LicenseLink
} from '../Components/index';

const countString = (number, label) => {
  if (number === 0) {
    return `No ${label}s`;
  }

  if (number === 1) {
    return `1 ${label}`;
  }

  return (
    <>
      <NumberFormatter>{number}</NumberFormatter> {label}s
    </>
  );
};

const AccountsPage = () => {
  const pagination = usePaginationReducer({ id: 'accounts' });
  const order = useOrderableTableReducer({ id: 'accounts' });

  const [editingClient, setEditingClient] = useState(null);
  const [creatingClient, setCreatingClient] = useState(false);
  const [chooseLogo, setChooseLogo] = useState(null);

  const { showSuccess } = useNotification();
  const { showModal, hideModal } = useModal();

  const queryClient = useQueryClient();
  const query = useQuery([
    'owner/clients',
    { ...pagination.params, ...order.params }
  ]);
  const { mutateAsync: deleteClient } = useMutation(deleteClientApi, {
    onSuccess: () => {
      queryClient.invalidateQueries();
      showSuccess(`Successfully deleted account.`);
    }
  });
  const { mutate: updateClientLogo } = useMutation(updateClientLogoApi, {
    onSuccess: () => {
      queryClient.invalidateQueries();
      showSuccess(`Successfully updated account logo.`);
    }
  });

  const handleEditClose = () => setEditingClient(null);

  const handleEdit = client => setEditingClient(client);

  const handleCreateClose = () => setCreatingClient(false);

  const handleCreate = () => setCreatingClient(true);

  const handleClientCreated = () => handleCreateClose();

  const handleDeleteClient = client => {
    showModal({
      header: 'Delete Account',
      body: `Are you sure you want to delete ${client.name}?`,
      handleConfirm: async () => {
        try {
          await deleteClient(client.id);
        } finally {
          hideModal();
        }
      }
    });
  };

  const hideChooseLogo = () => setChooseLogo(null);

  const handleLogoChange = logo => {
    updateClientLogo({
      id: chooseLogo.id,
      logo_id: logo.id,
      source_id: logo.source.id
    });
    hideChooseLogo();
  };

  return (
    <PageWrapper
      header="Accounts"
      subheader="Manage your accounts and their licenses"
      actions={
        <Flex col ySpaceSm>
          <Flex xSpace right>
            <Button as={Link} variant="secondary" to="/owner/licenses">
              Licenses
            </Button>
            <Button onClick={handleCreate}>+ Account</Button>
          </Flex>
          <Flex right>
            <Flex col right>
              <LicenseTotal />
              <LicenseLink>Request More licenses</LicenseLink>
            </Flex>
          </Flex>
        </Flex>
      }>
      {chooseLogo && (
        <ImageSelect
          filesQueryKey={`owner/clients/${chooseLogo.id}/files`}
          sourcesQueryKey={`owner/clients/${chooseLogo.id}/files/sources`}
          handleFileSelect={handleLogoChange}
          handleCancel={hideChooseLogo}
        />
      )}
      <StretchPanel>
        <Table loading={query.isLoading} loadingColumns={4}>
          <TableHead>
            <OrderableTableRow
              rows={[
                { label: 'Name', sort: 'name' },
                { label: 'Summary', sort: null },
                { label: 'Created', sort: 'created_at' },
                null
              ]}
              {...order.props}
            />
          </TableHead>
          {query.isSuccess && (
            <TableBody>
              {query.data.data.map((client, key) => (
                <TableRow key={key}>
                  <TableCell>
                    <ClientCard client={client} />
                  </TableCell>
                  <TableCell>
                    <Flex col>
                      <Span>{countString(client.user_count, 'User')}</Span>
                      <Span>
                        {countString(client.subscriber_count, 'Subscriber')}
                      </Span>
                      <Span>
                        {countString(client.message_count, 'Message')}
                      </Span>
                    </Flex>
                  </TableCell>
                  <TableCell>
                    <Flex col>
                      <Span>
                        {dayjs(client.created_at).format('DD/MM/YYYY')}
                      </Span>
                      <Span light>
                        {dayjs(client.created_at).from(dayjs())}
                      </Span>
                    </Flex>
                  </TableCell>
                  <TableActions>
                    <Button
                      variant="secondary"
                      icon="pencilAlt"
                      onClick={() => handleEdit(client)}>
                      Edit
                    </Button>
                    <Button
                      variant="secondary"
                      onClick={() => setChooseLogo(client)}>
                      Change logo
                    </Button>
                    <Button
                      colour="danger"
                      variant="secondary"
                      icon="trash"
                      onClick={() => handleDeleteClient(client)}>
                      Delete
                    </Button>
                  </TableActions>
                </TableRow>
              ))}
            </TableBody>
          )}
        </Table>
        {query.showPagination && (
          <Pagination pagination={query.data.meta} {...pagination.props} />
        )}
      </StretchPanel>
      <AnimatePresence>
        {editingClient && (
          <PanelSlider
            header="Edit Account"
            width="50vw"
            divider
            handleHide={handleEditClose}>
            <EditClient client={editingClient} />
          </PanelSlider>
        )}
      </AnimatePresence>
      <AnimatePresence>
        {creatingClient && (
          <PanelSlider
            header="Create Account"
            width="50vw"
            divider
            handleHide={handleCreateClose}>
            <CreateClient onClientCreated={handleClientCreated} />
          </PanelSlider>
        )}
      </AnimatePresence>
    </PageWrapper>
  );
};

export default AccountsPage;
