import React from 'react';

import { Settings as CoreSettings } from '../../../Screens/index';
import { ScreenContainer } from '../Components/index';

const Settings = () => {
  return (
    <ScreenContainer>
      <CoreSettings pad={false} />
    </ScreenContainer>
  );
};

export default Settings;
