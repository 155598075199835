import React from 'react';
import PropTypes from 'prop-types';

import actions from './Actions/index';

const ActionContent = ({ name, ...props }) => {
  const { Content } = actions.all.find(action => action.name === name);

  return <Content {...props} />;
};

ActionContent.propTypes = {
  name: PropTypes.oneOf(Object.keys(actions))
};

export default ActionContent;
