import { useRef, useEffect } from 'react';

const setRows = (ref, options) => {
  const previousRows = ref.rows;

  // Reset rows
  ref.rows = options.min;

  // Get how many rows to use, based on scroll height and text area height.
  const currentRows =
    Math.round(
      (ref.scrollHeight - options.initialTextAreaHeight) / options.lineHeight
    ) + options.min;

  // Rows haven't changed.
  if (currentRows === previousRows) {
    ref.rows = currentRows;
  }

  // Rows have hit limit, make scrollable.
  if (currentRows >= options.max) {
    ref.scrollTop = ref.scrollHeight;
    ref.rows = options.max;
  }

  ref.rows = currentRows < options.max ? currentRows : options.max;
};

const defaultOptions = {
  lineHeight: 18,
  initialTextAreaHeight: 35,
  min: 1,
  max: 10
};

const useAutoTextArea = (value, passedOptions = defaultOptions) => {
  const ref = useRef();

  useEffect(() => {
    const options = {
      ...defaultOptions,
      ...passedOptions
    };

    if (!ref?.current) {
      return;
    }

    // Don't use in testing.
    // https://github.com/testing-library/react-testing-library/issues/353#issuecomment-481248489
    if (process.env.NODE_ENV !== 'test') {
      setRows(ref.current, options);
    }
  }, [value, passedOptions, ref]);

  return ref;
};

export default useAutoTextArea;
export { setRows };
