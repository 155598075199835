import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import { useMutation, useQuery, useQueryClient } from '@ubisend/pulse-hooks';
import { Button, useNotification } from '@ubisend/pulse-components';
import { getTicket } from '@ubisend/pulse-tickets';

import { updateTicket as updateTicketApi } from '../../../../../api/index';

const ResolveButton = ({ ticket }) => {
  const history = useHistory();
  const { showSuccess } = useNotification();
  const queryClient = useQueryClient();

  const query = useQuery('live-chat/settings');

  const resolveTicket = useMutation(updateTicketApi, {
    onSuccess: ({ data }) => {
      showSuccess('Successfully resolved live chat ticket.');
      queryClient.invalidateQueries('tickets/ubidesk');

      if (query.data.data.has_agent_feedback_enabled) {
        history.push(`/live-chat/${data.data.id}/feedback`);
      } else {
        history.push(`/live-chat`);
      }
    }
  });

  const handleResolve = () => {
    resolveTicket.mutate({
      ...getTicket(ticket),
      status: 'resolved'
    });
  };

  return (
    <Button
      loading={query.isLoading}
      variant="secondary"
      icon="checkCircle"
      colour="positive"
      onClick={handleResolve}>
      Resolve
    </Button>
  );
};

ResolveButton.propTypes = {
  ticket: PropTypes.object.isRequired
};

export default ResolveButton;
