import React from 'react';
import PropTypes from 'prop-types';

import { Select } from '@ubisend/pulse-components';

import { useVariables } from '../../hooks/index';

const format = ({ id, name }) => ({ label: name, value: id });

const VariableSelect = ({ value, ...props }) => {
  const { variables } = useVariables();

  return (
    <Select
      isClearable
      options={variables ? variables.map(format) : []}
      value={
        variables && value
          ? format(variables.find(variable => variable.id === value))
          : null
      }
      {...props}
    />
  );
};

VariableSelect.propTypes = {
  value: PropTypes.number
};

export default VariableSelect;
