import dayjs from 'dayjs';

import {
  usePaginationReducer,
  useOrderableTableReducer,
  useFilterReducer
} from '@ubisend/pulse-components';
import { useLanguages } from '@ubisend/pulse-hooks';

const defaultStart = new Date(dayjs().subtract(1, 'weeks'));
const defaultEnd = new Date(dayjs().add('1', 'hours'));

const useFilters = ({ id }) => {
  const { defaultLanguage } = useLanguages();

  const defaultFilters = {
    start: defaultStart,
    end: defaultEnd,
    type: null,
    threshold: null,
    locations: [],
    language_id: defaultLanguage.id
  };

  const order = useOrderableTableReducer({ id });
  const pagination = usePaginationReducer({ id });
  const filters = useFilterReducer({
    id,
    initialFilters: defaultFilters,
    options: { pagination }
  });

  return {
    filters: filters.form,
    order,
    pagination,
    filterProps: filters,
    filterMenuProps: filters.props
  };
};

export default useFilters;
