import LogoutContent from './LogoutContent';

const logout = {
  name: 'Logout subscriber',
  Content: LogoutContent,
  setInitialContent: () => {
    return {};
  },
  valid: () => {
    return true;
  }
};

export default logout;
