// Core
const VERSION = process.env.REACT_APP_PULSE_VERSION;

// Messages
const MAX_MESSAGE_LENGTH = 1000;
const MIN_MESSAGE_LIMIT = 1;

// Files
const MAX_FILE_COUNT = 5;
const ONE_MEGABYTE_IN_BYTES = 1000000;
const COMBINED_MAX_FILE_MEGABYTES = 20;
const COMBINED_MAX_FILE_BYTES =
  ONE_MEGABYTE_IN_BYTES * COMBINED_MAX_FILE_MEGABYTES;
const UPLOADED_FILE_ID_PREFIX = 'temporary_file_message';

export {
  VERSION,
  MAX_MESSAGE_LENGTH,
  MIN_MESSAGE_LIMIT,
  MAX_FILE_COUNT,
  ONE_MEGABYTE_IN_BYTES,
  COMBINED_MAX_FILE_MEGABYTES,
  COMBINED_MAX_FILE_BYTES,
  UPLOADED_FILE_ID_PREFIX
};
