import { useReducer, useCallback, useEffect } from 'react';

const TYPES = {
  CHANGE_EDITABLE_FILTERS: 'CHANGE_EDITABLE_FILTERS',
  RESET_FILTERS: 'RESET_FILTERS',
  APPLY_FILTERS: 'APPLY_FILTERS'
};

const STATUSES = {
  CLEAN: 'CLEAN',
  DIRTY: 'DIRTY',
  SUBMITTED: 'SUBMITTED'
};

const reducer = (state, { type, ...params }) => {
  switch (type) {
    case TYPES.CHANGE_EDITABLE_FILTERS:
      return {
        status: STATUSES.DIRTY,
        filters: state.filters,
        editableFilters: { ...state.editableFilters, ...params.filters }
      };
    case TYPES.RESET_EDITABLE_FILTERS:
      return {
        status: STATUSES.CLEAN,
        filters: state.filters,
        editableFilters: { ...state.editableFilters, ...params.filters }
      };
    case TYPES.RESET_FILTERS:
      return {
        status: STATUSES.CLEAN,
        filters: params.filters,
        editableFilters: params.filters
      };
    case TYPES.APPLY_FILTERS: {
      const filters = params.filters || state.editableFilters;

      return {
        status: STATUSES.SUBMITTED,
        editableFilters: filters,
        filters
      };
    }
    default:
      throw new Error(`No type defined in useFilterReducer for ${type}`);
  }
};

const defaultOptions = {
  pagination: null
};

const getFiltersFromSessionStorage = id => {
  if (!id) {
    return null;
  }

  const filters = sessionStorage.getItem(`filters-${id}`);

  if (!filters) {
    return null;
  }

  return JSON.parse(filters);
};

const useFilterReducer = ({
  id,
  initialFilters,
  options: passedOptions = {}
}) => {
  const options = { ...defaultOptions, ...passedOptions };

  const filtersInSessionStorage = getFiltersFromSessionStorage(id);

  const [state, initialDispatch] = useReducer(
    reducer,
    filtersInSessionStorage || {
      status: STATUSES.CLEAN,
      filters: initialFilters,
      editableFilters: initialFilters
    }
  );

  const dispatch = useCallback(
    params => {
      const options = { ...defaultOptions, ...passedOptions };
      initialDispatch({ ...params }, options);
    },
    [initialDispatch, passedOptions]
  );

  const updateFilters = filters => {
    dispatch({ type: TYPES.CHANGE_EDITABLE_FILTERS, filters });
  };

  const handleApply = () => {
    if (options.pagination) {
      options.pagination.resetPage();
    }

    dispatch({ type: TYPES.APPLY_FILTERS });
  };

  const applyFilters = filters => {
    if (options.pagination) {
      options.pagination.resetPage();
    }

    dispatch({ type: TYPES.APPLY_FILTERS, filters });
  };

  const handleReset = () => {
    if (options.pagination) {
      options.pagination.resetPage();
    }

    dispatch({ type: TYPES.RESET_FILTERS, filters: initialFilters });
  };

  useEffect(() => {
    sessionStorage.setItem(`filters-${id}`, JSON.stringify(state));
  }, [id, state]);

  return {
    filters: state.editableFilters,
    status: state.status,
    updateFilters,
    form: state.filters,
    handleApply,
    handleReset,
    applyFilters,
    props: {
      status: state.status,
      STATUSES,
      handleApply,
      handleReset
    }
  };
};

export default useFilterReducer;
