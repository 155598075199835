import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Flex, Paragraph, Heading1 } from '@ubisend/pulse-components';

import {
  useDelivery,
  useBot,
  useMutation,
  useQuery
} from '../../../../hooks/index';
import { groupMessages } from '../../../../utilities/index';
import { saveMessage, deleteMessage } from '../../../../api/index';
import Notifications from '../Notifications';
import { MessagePreview } from './Components/index';
import { Button, ScreenContainer } from '../../Components/index';

const BookmarkIcon = () => (
  <svg
    fill="currentColor"
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg">
    <path d="M5 4a2 2 0 012-2h6a2 2 0 012 2v14l-5-2.5L5 18V4z" />
  </svg>
);

const ReplyIcon = () => (
  <svg
    fill="currentColor"
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      d="M7.707 3.293a1 1 0 010 1.414L5.414 7H11a7 7 0 017 7v2a1 1 0 11-2 0v-2a5 5 0 00-5-5H5.414l2.293 2.293a1 1 0 11-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
      clipRule="evenodd"
    />
  </svg>
);

const SavedContainer = styled.div`
  ${tw`w-full py-6`}
`;

const MessageSearch = styled.input`
  ${tw`text-black w-full md:w-auto px-3 h-8 rounded border-0`}
  box-sizing: border-box;
`;

const Message = styled.div`
  ${tw`flex md:px-8 md:py-6 px-4 py-3 md:mt-6 mt-4 block text-white no-underline`}
  border-radius: 1rem;
  background-color: rgba(255, 255, 255, 0.125);
  border: 2px solid rgba(255, 255, 255, 0.125);
  backdrop-filter: blur(8px);
`;

const Timestamp = styled.div`
  ${tw`flex items-center font-semibold font-poppins text-xs uppercase tracking-wide whitespace-no-wrap mt-3 md:mt-4 opacity-50`}
`;

const MessageActions = styled.div`
  ${tw`flex flex-col md:flex-row`}
`;

const ResendButton = styled(Button)`
  ${tw`flex-no-shrink py-2 mb-2 ml-3 md:mb-0`}
  & > svg {
    ${tw`mr-2`}
  }
`;

const SaveButton = styled(ResendButton)`
  & > svg {
    ${props =>
      !props.saved &&
      `& path {
        fill: transparent;
        stroke: currentColor;
        stroke-width: 2px;
      }`}
  }
`;

const getSubscriberMessages = group => {
  return Boolean(group.find(({ direction }) => direction === 'toServer'));
};

const searchMessageGroup = (messages, search) => {
  return Boolean(
    messages.find(message => {
      return message.content.text.toLowerCase().includes(search.toLowerCase());
    })
  );
};

const SavedMessages = ({ search }) => {
  const { sendMessage } = useDelivery();

  const { t } = useTranslation('full_page');

  const history = useHistory();

  const queryClient = useQueryClient();
  const { data, isSuccess } = useQuery('saved');
  const mutation = useMutation(deleteMessage, {
    onSuccess: () => {
      queryClient.invalidateQueries('saved');
    }
  });

  if (!isSuccess) {
    return null;
  }

  if (data.data.length === 0) {
    return null;
  }

  const handleDelete = message => event => {
    event.stopPropagation();
    mutation.mutate(message.id);
  };

  const handleSend = message => event => {
    event.stopPropagation();
    sendMessage(message.message);
    history.push('/window/body/messages');
  };

  return (
    <SavedContainer>
      <h2>{t('messages_group_header')}</h2>
      {data.data
        .filter(message => {
          return searchMessageGroup(
            [{ content: { text: message.message } }],
            search
          );
        })
        .map((message, key) => (
          <Message key={key}>
            <Flex fat between top>
              <Paragraph>{message.name}</Paragraph>
              <MessageActions>
                <ResendButton
                  onClick={handleSend(message)}
                  disabled={mutation.isDisabled}>
                  <ReplyIcon /> {t('resend_message_button')}
                </ResendButton>
                <SaveButton
                  saved
                  onClick={handleDelete(message)}
                  disabled={mutation.isDisabled}>
                  <BookmarkIcon />
                  {t('unsave_message_button')}
                </SaveButton>
              </MessageActions>
            </Flex>
            <Timestamp>
              <Notifications.Clock />
              {t('saved_at_label')} {dayjs().to(message.sentAt)}
            </Timestamp>
          </Message>
        ))}
    </SavedContainer>
  );
};

SavedMessages.propTypes = {
  search: PropTypes.string
};

const HeaderContainer = styled.div`
  ${tw`md:flex w-full justify-between`}
`;

const Messages = ({ groups, handleMessageClick, search }) => {
  const { sendMessage, hasChannel } = useDelivery();

  const { t } = useTranslation('full_page');

  const history = useHistory();

  const queryClient = useQueryClient();
  const mutation = useMutation(saveMessage, {
    onSuccess: () => {
      queryClient.invalidateQueries('saved');
    }
  });

  const handleSave = message => event => {
    event.stopPropagation();
    mutation.mutate({
      name: message.content.text,
      message: message.content.text
    });
  };

  const handleSend = message => event => {
    event.stopPropagation();
    sendMessage(message.content.text);
    history.push('/window/body/messages');
  };

  return groups
    .filter(getSubscriberMessages)
    .filter(group => searchMessageGroup(group, search))
    .map((group, key) => (
      <Message
        key={key}
        onClick={() => handleMessageClick(group[0])}
        style={{ cursor: 'pointer' }}>
        <Flex fat between top>
          <Paragraph>{group[0].content.text}</Paragraph>
          <MessageActions>
            <ResendButton
              onClick={handleSend(group[0])}
              disabled={mutation.isDisabled}>
              <ReplyIcon /> {t('resend_message_button')}
            </ResendButton>
            {hasChannel && (
              <SaveButton
                onClick={handleSave(group[0])}
                disabled={mutation.isDisabled}>
                <BookmarkIcon />
                {t('save_message_button')}
              </SaveButton>
            )}
          </MessageActions>
        </Flex>
        <Timestamp>
          <Notifications.Clock />
          {t('sent_at_label')} {dayjs().to(group[0].sentAt)}
        </Timestamp>
      </Message>
    ));
};

Messages.propTypes = {
  groups: PropTypes.arrayOf(
    PropTypes.shape({
      //
    }).isRequired
  ).isRequired,
  handleMessageClick: PropTypes.func.isRequired,
  search: PropTypes.string
};

const History = () => {
  const [group, setGroup] = useState(null);
  const [search, setSearch] = useState('');

  const { messages } = useBot();

  const { t } = useTranslation('full_page');

  const handleSearchChange = event => {
    setSearch(event.target.value);
  };

  const groups = useMemo(() => {
    return messages.length > 0 && messages.reduce(groupMessages, []);
  }, [messages]);

  const handleMessageClick = subscriberMessage => {
    const subscriberMessageGroupIndex = groups.findIndex(messages => {
      return messages.find(message => message.id === subscriberMessage.id);
    });

    const chatbotResponseGroup = groups
      .slice(subscriberMessageGroupIndex + 1)
      .find(group => group.find(message => message.direction === 'toClient'));

    setGroup(chatbotResponseGroup);
  };

  return group ? (
    <MessagePreview group={group} />
  ) : (
    <ScreenContainer>
      <HeaderContainer>
        <Heading1 initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
          {t('messages_header')}
        </Heading1>
        <MessageSearch
          onChange={handleSearchChange}
          placeholder={t('messages_search_placeholder')}
        />
      </HeaderContainer>
      <SavedMessages search={search} />
      <Messages
        search={search}
        groups={groups}
        handleMessageClick={handleMessageClick}
      />
    </ScreenContainer>
  );
};

export default History;
