import React from 'react';
import PropTypes from 'prop-types';

import { Select } from '@ubisend/pulse-components';

import { countries } from '../fixtures/index';

const CountrySelect = ({ value, ...props }) => (
  <Select
    options={countries}
    value={value ? countries.find(country => country.value === value) : null}
    {...props}
  />
);

CountrySelect.propTypes = {
  value: PropTypes.string
};

export default CountrySelect;
