import ExportSectionContent from './ExportSectionContent';

const exportImport = {
  name: 'Export knowledge base section',
  Content: ExportSectionContent,
  setInitialContent: (content = {}) => {
    return {
      email: content.email || '',
      section_id: content.section_id || null
    };
  },
  valid: content => {
    return Boolean(content.email && content.section_id);
  }
};

export default exportImport;
