import ExportImportContent from './ExportImportContent';

const exportImport = {
  name: 'Export single knowledge base',
  Content: ExportImportContent,
  setInitialContent: (content = {}) => {
    return { email: content.email || '', import_id: content.import_id || null };
  },
  valid: content => {
    return Boolean(content.email && content.import_id);
  }
};

export default exportImport;
