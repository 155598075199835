import array from './Array/index';
import boolean from './Boolean/index';
import number from './Number/index';
import string from './String/index';

const allTypes = [string, number, array, boolean];

const types = {
  array,
  boolean,
  number,
  string,
  default: allTypes,
  all: allTypes
};

export default types;
