import styled from 'styled-components';

import Footer, { animations } from './Footer';

const FixedFooter = styled(Footer)`
  box-sizing: border-box;
  width: calc(100vw - 12rem);
  ${tw`fixed pin-b pin-r z-20`}
`;

FixedFooter.defaultProps = {
  ...animations
};

export default FixedFooter;
