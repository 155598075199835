import React from 'react';
import PropTypes from 'prop-types';
import { MemoryRouter, Route, Switch } from 'react-router-dom';

import { Settings } from '../../Screens/index';
import {
  Launcher,
  Banner,
  Message,
  Messages,
  Notifications,
  Tickets
} from './Screens/index';
import {
  WindowHeader,
  ResetOnClose,
  LightBox,
  Window,
  KeyboardControls
} from './Components/index';
import { RouteChanger, AuthChanger } from '../../Components/index';
import { useBot } from '../../hooks/index';

const Converse = ({ start = '/', path = null }) => {
  const { lightBoxImage } = useBot();

  return (
    <MemoryRouter initialEntries={[start]}>
      <RouteChanger path={path} />
      <AuthChanger />
      <ResetOnClose />
      <KeyboardControls />
      {lightBoxImage && <LightBox />}
      <Switch>
        <Route exact path="/" component={Launcher} />
        <Route path="/window">
          <Window>
            <Route exact path="/window/banner" component={Banner} />
            <Route exact path="/window/messages/:id" component={Message} />
            <Route path="/window/body">
              <WindowHeader />
              <Route exact path="/window/body/messages" component={Messages} />
              <Route
                exact
                path="/window/body/notifications"
                component={Notifications}
              />
              <Route exact path="/window/body/tickets" component={Tickets} />
              <Route exact path="/window/body/settings" component={Settings} />
            </Route>
          </Window>
        </Route>
      </Switch>
    </MemoryRouter>
  );
};

Converse.propTypes = {
  start: PropTypes.string,
  path: PropTypes.string
};

export default Converse;
