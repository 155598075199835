import React, { useState, useEffect, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';

import {
  useSubscription,
  useSubscriptionContext
} from '@ubisend/pulse-subscribe';

import { appendServerMessages, appendMessage } from '../utilities/index';
import useDelayedBot, {
  defaultOptions as defaultDelayedBotOptions
} from '../hooks/useDelayedBot';
import { useAuth } from '../hooks/index';
import { DeliveryContext } from '../Contexts/index';

const DeliveryProvider = ({
  children,
  demo = false,
  client_id,
  base_url,
  channelDriverName = 'Converse',
  delayedBotOptions = defaultDelayedBotOptions,
  browserUrl = null,
  variables
}) => {
  const [subscribed, setSubscribed] = useState(false);

  const { setMessages, setQueuedMessages } = useDelayedBot(delayedBotOptions);
  const { connected } = useSubscriptionContext();
  const { token } = useAuth();
  const channelRef = useRef();

  const getChannelParams = useCallback(() => {
    return {
      client_id,
      token,
      url: browserUrl,
      variables,
      demo
    };
  }, [client_id, token, browserUrl, variables, demo]);

  const handleSubscribe = useCallback(data => {
    channelRef.current = data.channel;

    setSubscribed(true);
  }, []);

  const handleMessage = useCallback(
    messages => setQueuedMessages(appendServerMessages(messages)),
    [setQueuedMessages]
  );

  const handleUnsubscribe = useCallback(() => null, []);

  const subscribe = useSubscription('subscribe', handleSubscribe);
  const unsubscribe = useSubscription('unsubscribe', handleUnsubscribe);
  const message = useSubscription('message', handleMessage);

  const sendMessage = (newMessage, postback = null) => {
    // Add the messages to the widget, as well as a typing indicator.
    setMessages(appendMessage(newMessage));
    message({
      ...getChannelParams(),
      message: newMessage,
      postback: postback
    });
  };

  /**
   * Subscribe on mount.
   */
  useEffect(() => {
    subscribe({
      ...getChannelParams(),
      channel_driver_name: channelDriverName
    });

    return () => {
      unsubscribe({ channel: channelRef.current });
    };
  }, [subscribe, unsubscribe, getChannelParams, connected, channelDriverName]);

  const context = {
    // API
    base_url,
    // Browser
    browserUrl,
    variables,
    // Client
    client_id,
    // Channel details
    subscribed,
    hasChannel: true,
    getChannelParams,
    channelDriverName,
    // Send events.
    sendMessage,
    subscribe
  };

  return (
    <DeliveryContext.Provider value={context}>
      {children}
    </DeliveryContext.Provider>
  );
};

DeliveryProvider.propTypes = {
  channelDriverName: PropTypes.string.isRequired,
  client_id: PropTypes.string.isRequired,
  base_url: PropTypes.string.isRequired,
  channelKey: PropTypes.string,
  variables: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired
    })
  ),
  seenBannerKey: PropTypes.string,
  demo: PropTypes.bool,
  delayedBotOptions: PropTypes.shape({
    messageDelay: PropTypes.number,
    maxTypingTime: PropTypes.number
  }),
  browserUrl: PropTypes.string
};

export default DeliveryProvider;
