import React from 'react';
import dayjs from 'dayjs';

import { useQuery } from '@ubisend/pulse-hooks';
import {
  PageWrapper,
  Placeholder,
  Flex,
  NoSubcriberGraphic,
  Heading3,
  Panel,
  Label,
  Heading2,
  Paragraph,
  LeftHalfLayout
} from '@ubisend/pulse-components';
import {
  SubscriberDetails,
  DetailsPanel,
  MainSection,
  NoSubscriber,
  TopSection
} from '@ubisend/pulse-tickets';

const Feedback = ({ match }) => {
  const query = useQuery(`feedback/${match.params.id}`);

  return (
    <Flex>
      <MainSection>
        <PageWrapper header="Feedback">
          <Flex ySpace col>
            <Panel>
              <Flex col mb>
                <TopSection>
                  <Heading2>Feedback Details</Heading2>
                </TopSection>
                {query.isSuccess && (
                  <Label secondary>
                    {dayjs(query.data.data.created_at).from(dayjs())} by
                    Subscriber #{query.data.data.subscriber.id}
                  </Label>
                )}
              </Flex>
              <Flex>
                {query.isSuccess && (
                  <LeftHalfLayout>
                    <Label>Feedback</Label>
                    <Paragraph>{query.data.data.message}</Paragraph>
                  </LeftHalfLayout>
                )}
              </Flex>
            </Panel>
          </Flex>
        </PageWrapper>
      </MainSection>
      <DetailsPanel divider header="Subscriber Details">
        {query.isLoading && <Placeholder ySpace />}
        {!query.isLoading && query.data.data.subscriber && (
          <SubscriberDetails subscriber={query.data.data.subscriber} />
        )}
        {!query.isLoading && !query.data.data.subscriber && (
          <NoSubscriber col center ySpace middle>
            <NoSubcriberGraphic />
            <Heading3>No Subscriber attached to this log</Heading3>
          </NoSubscriber>
        )}
      </DetailsPanel>
    </Flex>
  );
};

export default Feedback;
