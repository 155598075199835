import React from 'react';
import PropTypes from 'prop-types';

import { Flex, DateTimePicker, Label } from '@ubisend/pulse-components';
import { useLocations, useLanguages } from '@ubisend/pulse-hooks';
import { LocationSelect } from '@ubisend/pulse-faqs';
import { useAuth } from '@ubisend/pulse-auth';

import LanguageSelect from './LanguageSelect';
import ThresholdSelect from './ThresholdSelect';

const Filter = ({ filters, updateFilters }) => {
  const { hasPermission } = useAuth();
  const { hasLocations } = useLocations();
  const { languages } = useLanguages();

  const handleNewStart = ([newStart]) => {
    updateFilters({ start: newStart });
  };

  const handleNewEnd = ([newEnd]) => {
    updateFilters({ end: newEnd });
  };

  const handleThresholdChange = option => {
    updateFilters({ threshold: option ? option.value : null });
  };

  const handleLocationSelect = items => {
    updateFilters({
      locations: items ? items.map(item => item.value) : []
    });
  };

  const handleLanguageSelect = ({ value }) => {
    updateFilters({ language_id: value });
  };

  const showLocations = hasPermission('view locations') && hasLocations;

  const showLanguages = languages.length > 1;

  return (
    <Flex col fat style={{ width: '20rem' }}>
      <Flex col fat mb>
        <Label htmlFor="start">Start</Label>
        <DateTimePicker
          id="start"
          date={filters.start}
          onChange={handleNewStart}
        />
      </Flex>
      <Flex col fat mb>
        <Label htmlFor="end">End</Label>
        <DateTimePicker id="end" date={filters.end} onChange={handleNewEnd} />
      </Flex>
      <Flex col fat mb={showLanguages || showLocations}>
        <Label htmlFor="confidence">Chatbot confidence</Label>
        <ThresholdSelect
          id="confidence"
          onChange={handleThresholdChange}
          value={filters.threshold}
          isClearable
        />
      </Flex>
      {showLocations && (
        <Flex col fat mb={showLanguages}>
          <Label htmlFor="locations">Location</Label>
          <LocationSelect
            id="locations"
            value={filters.locations}
            onChange={handleLocationSelect}
            isMulti
          />
        </Flex>
      )}
      {showLanguages && (
        <Flex col fat>
          <Label htmlFor="language">Language</Label>
          <LanguageSelect
            id="language"
            value={filters.language_id}
            onChange={handleLanguageSelect}
          />
        </Flex>
      )}
    </Flex>
  );
};

Filter.propTypes = {
  filters: PropTypes.shape({
    start: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.string,
      PropTypes.instanceOf(Date)
    ]),
    end: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.string,
      PropTypes.instanceOf(Date)
    ]),
    threshold: PropTypes.string,
    type: PropTypes.string,
    locations: PropTypes.array.isRequired,
    language_id: PropTypes.number.isRequired
  }).isRequired,
  updateFilters: PropTypes.func.isRequired
};

export default Filter;
