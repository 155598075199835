import ExportAllImportsContent from './ExportAllImportsContent';

const exportAllImports = {
  name: 'Export all knowledge bases',
  Content: ExportAllImportsContent,
  setInitialContent: (content = {}) => {
    return {
      email: content.email || ''
    };
  },
  valid: content => {
    return Boolean(content.email);
  }
};

export default exportAllImports;
