import React from 'react';
import PropTypes from 'prop-types';

import { useQuery, useMutation, useQueryClient } from '@ubisend/pulse-hooks';
import { Placeholder, useNotification } from '@ubisend/pulse-components';

import { updateActiveHours } from '../api/index';
import ActiveHoursDetails from './ActiveHoursDetails';

const ActiveHours = ({ query: queryKey, disabled }) => {
  const { showSuccess } = useNotification();

  const queryClient = useQueryClient();
  const query = useQuery(queryKey);
  const mutation = useMutation(updateActiveHours, {
    onSuccess: () => {
      queryClient.invalidateQueries(queryKey);
      showSuccess('Successfully updated working hours');
    }
  });

  const handleSubmit = hours => {
    mutation.mutate({ url: queryKey, hours });
  };

  if (query.isLoading) {
    return <Placeholder />;
  }

  return (
    <ActiveHoursDetails
      initialActiveHours={query.data.data}
      onSubmit={handleSubmit}
      disabled={disabled}
      loading={mutation.isLoading}
    />
  );
};

ActiveHours.propTypes = {
  query: PropTypes.string.isRequired,
  disabled: PropTypes.bool
};

export default ActiveHours;
