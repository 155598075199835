import UniqueInput from './UniqueInput';

const unique = {
  name: 'Unique',
  Input: UniqueInput,
  content: {},
  format: (variable, content) => {
    if (content.uniqueKey) {
      return `${variable}|unique("${content.uniqueKey}")`;
    }
    return `${variable}|unique`;
  },
  valid: () => true
};

export default unique;
