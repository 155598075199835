import MessageInput from './MessageInput';

const message = {
  id: 'message',
  name: 'Message',
  Input: MessageInput,
  content: {
    type: 'text',
    placeholder: 'Send a message',
    disabled: false,
    show_file_upload: false
  },
  valid: content => {
    return Boolean(content.placeholder);
  },
  snippet: `{
    "type": "message",
    "content": {
      "type": "text",
      "placeholder": "Send a message",
      "disabled": false,
      "show_file_upload": false,
    }
}`
};

export default message;
