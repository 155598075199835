import {
  MarkdownLink,
  MarkdownBlockQuote,
  MarkdownCode,
  MarkdownEmphasis,
  MarkdownHeading1,
  MarkdownHeading2,
  MarkdownHeading3,
  MarkdownHeading4,
  MarkdownHeading5,
  MarkdownHeading6,
  MarkdownBreak,
  MarkdownImage,
  MarkdownListItem,
  MarkdownOrderedList,
  MarkdownUnorderedList,
  MarkdownParagraph,
  MarkdownPreformattedText
} from './Native/index';
import {
  MarkdownTable,
  MarkdownTableHead,
  MarkdownTableCell,
  MarkdownTableBody,
  MarkdownTableRow,
  MarkdownDelete,
  MarkdownUnderline
} from './NotNative/index';
import NotSupported from './NotSupported';

const tags = {
  // Native markdown elements.
  h1: MarkdownHeading1,
  h2: MarkdownHeading2,
  h3: MarkdownHeading3,
  h4: MarkdownHeading4,
  h5: MarkdownHeading5,
  h6: MarkdownHeading6,
  p: MarkdownParagraph,
  img: MarkdownImage,
  a: MarkdownLink,
  ol: MarkdownOrderedList,
  ul: MarkdownUnorderedList,
  blockquote: MarkdownBlockQuote,
  code: MarkdownCode,
  em: MarkdownEmphasis,
  hr: MarkdownBreak,
  li: MarkdownListItem,
  pre: MarkdownPreformattedText,
  // Not native markdown elements (via remark-gfm)
  table: MarkdownTable,
  thead: MarkdownTableHead,
  th: MarkdownTableCell,
  tbody: MarkdownTableBody,
  td: MarkdownTableCell,
  tr: MarkdownTableRow,
  del: MarkdownDelete,
  u: MarkdownUnderline,
  input: NotSupported
};

export default tags;
