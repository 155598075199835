import React, { useState } from 'react';

import {
  Toggle,
  Heading3,
  useNotification,
  Label,
  Span,
  Flex,
  Placeholder
} from '@ubisend/pulse-components';
import { useQuery, useMutation, useQueryClient } from '@ubisend/pulse-hooks';

import { updateTrainSettings } from '../api/index';

const TrainingSettings = () => {
  const [settings, setSettings] = useState();

  const { showSuccess } = useNotification();

  const queryClient = useQueryClient();
  useQuery('trains/settings', {
    onSuccess: ({ data }) => {
      setSettings(data);
    }
  });
  const mutation = useMutation(updateTrainSettings, {
    onSuccess: () => {
      queryClient.invalidateQueries('trains/settings');
    }
  });

  const handleTrainDailyChange = () => {
    const newSettings = {
      ...settings,
      train_daily: !settings.train_daily
    };
    mutation.mutate(newSettings);
    setSettings(newSettings);
    showSuccess('Successfully updated train settings');
  };

  return (
    <div>
      <Flex mb>
        <Heading3>Settings</Heading3>
      </Flex>
      {!settings && <Placeholder items={1} />}
      {settings && (
        <Flex col>
          <Flex col>
            <Label>Daily Train</Label>
            <Flex xSpace center>
              <Toggle
                on={settings.train_daily}
                handleToggleChange={handleTrainDailyChange}
              />
              <Span tinyText>Automatically train your chatbot every night</Span>
            </Flex>
          </Flex>
        </Flex>
      )}
    </div>
  );
};

export default TrainingSettings;
