import LoginContent from './LoginContent';

const login = {
  name: 'Login subscriber',
  Content: LoginContent,
  setInitialContent: (content = {}) => {
    return {
      email: content.email || '',
      password: content.password || ''
    };
  },
  valid: content => {
    return Boolean(content.email && content.password);
  }
};

export default login;
