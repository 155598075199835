import React from 'react';
import PropTypes from 'prop-types';

import { useQuery } from '@ubisend/pulse-hooks';
import { Select } from '@ubisend/pulse-components';

const format = ({ id, name }) => ({ label: name, value: id });

const SheetSelect = ({ value, ...props }) => {
  const { data, isLoading, isSuccess } = useQuery('spreadsheets');

  const getExistingSheet = () => {
    const sheet = data.data.find(
      sheet => sheet.id === value && sheet.lookups.length > 0
    );

    if (!sheet) {
      return null;
    }

    return format(sheet);
  };

  return (
    <Select
      isLoading={isLoading}
      isClearable
      options={
        isSuccess
          ? data.data.filter(({ lookups }) => lookups.length > 0).map(format)
          : []
      }
      value={isSuccess && value && getExistingSheet()}
      {...props}
    />
  );
};

SheetSelect.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default SheetSelect;
