import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { motion } from '@ubisend/framer-motion';
import { breakpoints } from '@ubisend/pulse-components';

import Image from './Image';
import Video from './Video';
import Slider from './Slider';

const StyledAside = styled(motion.aside)`
  @media (max-width: ${breakpoints.md - 1}px) {
    ${tw`flex flex-col items-end w-full`}
    & > img,  & iframe, & > div:first-child {
      ${tw`w-full`}
      height: 37.5vh;
    }
  }

  @media (min-width: ${breakpoints.md}px) {
    ${tw`md:h-full flex flex-col items-center justify-center h-full p-4`}
    ${props => !props.visible && 'margin-right: -45vw;'}
    width: 45vw;
    & > img {
      border-radius: 1rem;
    }
  }
`;

const VideoSlideIn = ({ message }) => {
  return <Video content={message.content} />;
};

VideoSlideIn.propTypes = {
  message: PropTypes.object.isRequired
};

const ImageSlideIn = ({ message }) => {
  return (
    <Image
      src={message.content.url}
      alt={message.content.image_description || 'Image Message'}
    />
  );
};

ImageSlideIn.propTypes = {
  message: PropTypes.shape({
    content: PropTypes.shape({
      url: PropTypes.string.isRequired,
      image_description: PropTypes.string
    })
  }).isRequired
};

const GeneralSlideIn = ({ message }) => {
  return (
    <Image
      src={message.content.image}
      alt={message.content.image_description || 'Image Message'}
    />
  );
};

GeneralSlideIn.propTypes = {
  message: PropTypes.shape({
    content: PropTypes.shape({
      image: PropTypes.string.isRequired,
      image_description: PropTypes.string
    })
  }).isRequired
};

const CarouselSlideIn = ({ message, active, setActive }) => {
  return (
    <Slider content={message.content} active={active} setActive={setActive} />
  );
};

CarouselSlideIn.propTypes = {
  message: PropTypes.shape({
    content: PropTypes.object.isRequired
  }).isRequired,
  active: PropTypes.number.isRequired,
  setActive: PropTypes.func.isRequired
};

const types = {
  image: ImageSlideIn,
  video: VideoSlideIn,
  general: GeneralSlideIn,
  carousel: CarouselSlideIn
};

const MediaMessageSlideIn = ({ visible, message, active, setActive }) => {
  const Component = types[message.type];

  return (
    <StyledAside
      layout="position"
      visible={visible}
      animate={{ opacity: visible ? 1 : 0 }}>
      {visible && (
        <Component message={message} active={active} setActive={setActive} />
      )}
    </StyledAside>
  );
};

MediaMessageSlideIn.propTypes = {
  visible: PropTypes.bool,
  message: PropTypes.shape({
    type: PropTypes.string.isRequired
  }).isRequired,
  active: PropTypes.number.isRequired,
  setActive: PropTypes.func.isRequired
};

export default MediaMessageSlideIn;
