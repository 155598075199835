import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Flex } from '@ubisend/pulse-components';

import HeaderButton from '../HeaderButton';
import HeaderSvg from '../HeaderSvg';
import { UnreadNotifications } from '../../../../../Components/index';
import { useNotifications } from '../../../../../hooks/index';

const NotificationButton = ({ unread, scale, ...rest }) => {
  const [showNumber, setShowNumber] = useState(false);
  const { unreadCount } = useNotifications();

  return (
    <Flex relative>
      {unreadCount > 0 && (
        <Flex absolute pinRight pinTop>
          <UnreadNotifications scale={scale} showNumber={showNumber}>
            {unreadCount > 9 ? '9+' : unreadCount}
          </UnreadNotifications>
        </Flex>
      )}
      <HeaderButton
        type="button"
        onMouseOver={() => setShowNumber(true)}
        onMouseLeave={() => setShowNumber(false)}
        {...rest}>
        <HeaderSvg
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          viewBox="0 0 24 24"
          stroke="currentColor">
          <path d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9" />
        </HeaderSvg>
      </HeaderButton>
    </Flex>
  );
};

NotificationButton.propTypes = {
  unread: PropTypes.number,
  scale: PropTypes.number
};

export default NotificationButton;
