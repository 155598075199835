import React from 'react';
import PropTypes from 'prop-types';

import {
  FormGroup,
  Label,
  Checkbox,
  TextInput,
  Flex,
  Select
} from '@ubisend/pulse-components';

import useComposer from '../../../hooks/useComposer';

const TypeSelect = ({ value, ...props }) => {
  const options = [
    { label: 'Text', value: 'text' },
    { label: 'Password', value: 'password' }
  ];

  return (
    <Select
      options={options}
      value={
        value ? options.find(option => option.value === value) : options[0]
      }
      {...props}
    />
  );
};

TypeSelect.propTypes = {
  value: PropTypes.string
};

const MessageInput = () => {
  const composer = useComposer();

  const handleTypeChange = option => {
    composer.dispatch({
      type: composer.TYPES.UPDATE_CONTENT,
      content: { type: option.value }
    });
  };

  const handlePlaceholderChange = event => {
    const placeholder = event.target.value;

    composer.dispatch({
      type: composer.TYPES.UPDATE_CONTENT,
      content: { placeholder }
    });
  };

  const handleDisabledChange = () => {
    composer.dispatch({
      type: composer.TYPES.UPDATE_CONTENT,
      content: { disabled: !composer.content.disabled }
    });
  };

  const handleShowFileUploadChange = () => {
    composer.dispatch({
      type: composer.TYPES.UPDATE_CONTENT,
      content: { show_file_upload: !composer.content.show_file_upload }
    });
  };

  return (
    <>
      <FormGroup>
        <Label htmlFor="type">Input type</Label>
        <TypeSelect
          id="type"
          value={composer.content.type}
          onChange={handleTypeChange}
        />
      </FormGroup>
      <FormGroup>
        <Label htmlFor="placeholder">Placeholder</Label>
        <TextInput
          id="placeholder"
          placeholder="Send a message"
          value={composer.content.placeholder}
          onChange={handlePlaceholderChange}
        />
      </FormGroup>
      <FormGroup>
        <Checkbox
          checked={composer.content.disabled}
          onChange={handleDisabledChange}
          label="Deactivate composer"
        />
      </FormGroup>
      <Flex>
        <Checkbox
          checked={composer.content.show_file_upload}
          onChange={handleShowFileUploadChange}
          label="Show file upload"
        />
      </Flex>
    </>
  );
};

export default MessageInput;
