import { useReducer, useEffect } from 'react';

const TYPES = {
  CHANGE_ORDER: 'CHANGE_ORDER',
  CHANGE_DIRECTION: 'CHANGE_DIRECTION'
};

const reducer = (state, { type, ...params }) => {
  switch (type) {
    case TYPES.CHANGE_ORDER:
      return { order: params.order, direction: 'ascending' };
    case TYPES.CHANGE_DIRECTION:
      return {
        order: state.direction === 'descending' ? null : state.order,
        direction: state.direction === 'descending' ? null : 'descending'
      };
    default:
      throw new Error(
        `No event defined in useOrderableTableReducer for ${type}`
      );
  }
};

const defaultOrder = {
  order: null,
  direction: null
};

const getOrderFromSessionStorage = id => {
  if (!id) {
    return null;
  }

  const order = sessionStorage.getItem(`order-${id}`);

  if (!order) {
    return null;
  }

  return JSON.parse(order);
};

const useOrderableTableReducer = ({ id, initialOrder: passedOrder = {} }) => {
  const builtOrder = {
    ...defaultOrder,
    ...passedOrder
  };

  const [order, dispatch] = useReducer(
    reducer,
    getOrderFromSessionStorage(id) || builtOrder
  );

  const handleOrderChange = row => {
    dispatch({ type: TYPES.CHANGE_ORDER, order: row.sort });
  };

  const handleDirectionChange = () => {
    dispatch({ type: TYPES.CHANGE_DIRECTION });
  };

  useEffect(() => {
    sessionStorage.setItem(`order-${id}`, JSON.stringify(order));
  }, [id, order]);

  return {
    ...order,
    dispatch,
    params: {
      order: order.order,
      direction: order.direction
    },
    props: {
      handleOrderChange,
      handleDirectionChange,
      order: order.order,
      direction: order.direction
    },
    TYPES
  };
};

export default useOrderableTableReducer;
