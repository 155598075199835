import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { useQuery } from '@ubisend/pulse-hooks';
import { Select, Label, LoadingContainer } from '@ubisend/pulse-components';

const SelectPage = ({ user, handlePageSelect }) => {
  const [pages, setPages] = useState();

  const { isLoading } = useQuery(
    ['facebook/pages', { access_token: user.accessToken }],
    {
      enabled: Boolean(user),
      onSuccess: ({ data }) => {
        setPages(data);
      }
    }
  );

  return (
    <>
      {isLoading && <LoadingContainer />}
      {!isLoading && pages && (
        <>
          <Label>Page to connect</Label>
          <Select
            style={{ flexGrow: 1, flexShrink: 0 }}
            aria-label="facebook-page-select"
            options={pages.map(({ name, id, access_token }) => {
              return { value: { id, access_token }, label: name };
            })}
            onChange={handlePageSelect}
          />
        </>
      )}
    </>
  );
};

SelectPage.propTypes = {
  user: PropTypes.shape({
    accessToken: PropTypes.string
  }).isRequired,
  handlePageSelect: PropTypes.func.isRequired
};

export default SelectPage;
