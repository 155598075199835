import React from 'react';
import PropTypes from 'prop-types';

const AddTag = ({ tag }) => {
  const Component = tag.AddTag;

  return <Component />;
};

AddTag.propTypes = {
  tag: PropTypes.shape({
    AddTag: PropTypes.element.isRequired
  }).isRequired
};

export default AddTag;
