import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import chroma from 'chroma-js';

import { Flex, pseudoStyles } from '@ubisend/pulse-components';
import { motion } from '@ubisend/framer-motion';

import Bubble from './Bubble';

const ButtonContainer = styled(Flex)`
  ${tw`flex-wrap`};
  max-width: 75%;
  justify-content: ${props => {
    return props.direction === 'recievedMessage' ? 'flex-end' : 'unset';
  }};
`;

const Button = styled(motion.button)`
  ${tw`cursor-pointer text-sm mr-2 mt-2 rounded-full bg-white whitespace-no-wrap flex-no-shrink`};
  padding: 0.4rem 0.85rem;
  border: 1px solid
    ${props => chroma(props.theme.bot[props.direction].background).alpha(0.3)};
  transition: all ease 0.1s;
  color: ${props => props.theme.bot[props.direction].background};
  &:hover {
    background: ${props =>
      chroma(props.theme.bot[props.direction].background).alpha(0.1)};
  }
  &:active {
    background: ${props =>
      chroma(props.theme.bot[props.direction].background).alpha(0.05)};
  }
  &:last-child {
    ${tw`mr-0`};
  }
  ${pseudoStyles}
`;

const QuickReply = ({
  handleButtonClick,
  content,
  direction,
  showResponses = true,
  ...props
}) => {
  const clickButton = ({ label, postback }) => {
    handleButtonClick(label, postback);
  };

  const containerAnimation = {
    show: {
      transition: {
        staggerChildren: 0.1,
        staggerDirection: direction === 'recievedMessage' ? -1 : 1
      }
    }
  };

  return (
    <Flex
      col
      fat
      style={{
        minWidth: 0,
        alignItems: direction === 'recievedMessage' ? 'end' : ''
      }}>
      <Bubble direction={direction} {...props}>
        {content.text}
      </Bubble>
      {showResponses && direction !== 'recievedMessage' && (
        <ButtonContainer
          data-testid="button-container"
          direction={direction}
          variants={containerAnimation}
          initial="hidden"
          animate="show"
          xSpaceSm>
          {content.responses.map((response, index) => (
            <Button
              key={index}
              data-testid="button"
              type="button"
              variants={{
                hidden: { opacity: 0, scale: 0 },
                show: { opacity: 1, scale: 1 }
              }}
              transition={{ ease: [0.16, 1, 0.3, 1], duration: 0.6 }}
              onClick={() => clickButton(response)}
              direction={direction}>
              {response.label}
            </Button>
          ))}
        </ButtonContainer>
      )}
    </Flex>
  );
};

QuickReply.propTypes = {
  content: PropTypes.shape({
    text: PropTypes.string.isRequired,
    responses: PropTypes.array
  }),
  direction: Bubble.propTypes.direction,
  handleButtonClick: PropTypes.func,
  showResponses: PropTypes.bool
};

export default QuickReply;
export { Button };
