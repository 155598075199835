import { useQuery as reactQueryUseQuery, useQueryClient } from 'react-query';
import { CancelToken } from 'axios';

import useAxios from './useAxios';

const useQuery = (queryKey, options = {}) => {
  const {
    enabled: passedEnabled = true,
    baseUrl,
    token,
    clientId,
    browserUrl,
    variables,
    channelDriverName,
    ...useQueryOptions
  } = options;

  const axios = useAxios({
    baseUrl,
    token,
    clientId,
    browserUrl,
    variables,
    channelDriverName
  });

  const isInTest = process.env.NODE_ENV === 'test';

  const queryFunction = async ({ queryKey }) => {
    const [key, params] = queryKey;

    const source = CancelToken.source();

    const promise = axios.get(key, {
      cancelToken: source.token,
      params
    });

    promise.cancel = () => {
      source.cancel('Query was cancelled by React Query');
    };

    const { data } = await promise;

    return data;
  };

  const queryClient = useQueryClient();

  const isGloballyDisabled = !queryClient.defaultOptions.queries.enabled;

  const query = reactQueryUseQuery({
    queryKey,
    queryFn: isInTest
      ? queryClient.defaultOptions.queries.queryFn
      : queryFunction,
    enabled: isGloballyDisabled ? false : passedEnabled,
    ...useQueryOptions
  });

  return query;
};

export default useQuery;
