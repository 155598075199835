import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { useQuery } from '@ubisend/pulse-hooks';
import { Select } from '@ubisend/pulse-components';

const format = ({ id, content }) => ({
  value: id,
  label: content.name
});

const SectionSelect = ({ value, import: item, ...props }) => {
  const { data, isLoading, isSuccess } = useQuery('imports');

  const disabled = !item;

  const sections = useMemo(() => {
    if (!item || !isSuccess) {
      return null;
    }

    return data.data.find(({ id }) => id === item).sections;
  }, [data, isSuccess, item]);

  return (
    <Select
      isLoading={isLoading}
      isDisabled={disabled}
      placeholder={'Home Page'}
      options={sections ? sections.map(format) : []}
      value={
        sections &&
        value &&
        format(sections.find(section => section.id === value))
      }
      {...props}
    />
  );
};

SectionSelect.propTypes = {
  value: PropTypes.number,
  import: PropTypes.number
};

export default SectionSelect;
