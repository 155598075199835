import ExportTranscriptContent from './ExportTranscriptContent';

const exportTranscript = {
  name: 'Export transcript',
  Content: ExportTranscriptContent,
  setInitialContent: (content = {}) => {
    return {
      email: content.email || ''
    };
  },
  valid: content => {
    return Boolean(content.email);
  }
};

export default exportTranscript;
