import React from 'react';
import PropTypes from 'prop-types';

import { ButtonSelect } from '@ubisend/pulse-components';
import { useLanguages } from '@ubisend/pulse-hooks';

const format = language => ({
  value: language.id,
  label: language.name,
  valid: language.valid
});

const LanguageSelect = ({ value, ...props }) => {
  const { languages } = useLanguages();

  return (
    <ButtonSelect
      aria-label="language-select"
      isLoading={!languages}
      options={languages ? languages.map(format) : []}
      value={
        value && languages
          ? format(languages.find(({ id }) => id === value))
          : null
      }
      {...props}
    />
  );
};

LanguageSelect.propTypes = {
  value: PropTypes.number,
  isValidLanguage: PropTypes.func
};

export default LanguageSelect;
