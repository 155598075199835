import SetPasswordContent from './SetPasswordContent';

const setPassword = {
  name: 'Set subscriber password',
  Content: SetPasswordContent,
  setInitialContent: (content = {}) => {
    return {
      email: content.email || '',
      token: content.token || '',
      password: content.password || ''
    };
  },
  valid: content => {
    return Boolean(content.email && content.token && content.password);
  }
};

export default setPassword;
