import React from 'react';
import PropTypes from 'prop-types';

import { Flex, Span } from '@ubisend/pulse-components';
import { TemplatingTextInput } from '@ubisend/pulse-templating';

const SetLocationContent = ({ content, onChange }) => {
  const handleLocationChange = location => {
    onChange({ location });
  };

  return (
    <Flex xSpace fat center>
      <Span>using</Span>
      <Flex col fat>
        <TemplatingTextInput
          id="location"
          placeholder="England"
          value={content.location}
          onChange={handleLocationChange}
        />
      </Flex>
    </Flex>
  );
};

SetLocationContent.propTypes = {
  onChange: PropTypes.func.isRequired,
  content: PropTypes.shape({
    location: PropTypes.string.isRequired
  }).isRequired
};

export default SetLocationContent;
