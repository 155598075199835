import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ReactSelect from 'react-select';
import { useTranslation } from 'react-i18next';
import chroma from 'chroma-js';

import { Flex } from '@ubisend/pulse-components';

import BurgerButton from '../BurgerButton';
import SendButton from '../SendButton';
import BaseComposer from '../BaseComposer';
import {
  useComposer,
  useDelivery,
  useBot,
  useTheme
} from '../../../../../hooks/index';

const Container = styled(BaseComposer.Container)`
  ${tw`h-16 flex items-center`}
`;

const StyledSelect = styled(ReactSelect)`
  ${tw`w-full h-full flex`}
`;

const Select = props => {
  const { sentMessage } = useTheme();

  const { t } = useTranslation('bots');

  const styleOverrides = {
    control: () => tw`w-full flex items-center`,
    container: () => tw`static px-4`,
    valueContainer: styles => ({
      maxHeight: 36,
      ...styles,
      ...tw`p-0 overflow-y-auto`
    }),
    menu: base => ({
      ...base,
      margin: '0.5rem',
      left: 0,
      width: 'calc(100% - 1rem)'
    })
  };

  const styles = {
    multiValueLabel: styles => ({
      ...styles,
      ...tw`font-poppins uppercase leading-none text-xs`,
      color: sentMessage.text
    }),
    multiValue: styles => ({
      ...styles,
      backgroundColor: chroma(sentMessage.background).alpha(0.8).css(),
      marginTop: -1
    }),
    multiValueRemove: styles => ({ ...styles, color: sentMessage.text }),
    placeholder: styles => ({ ...styles, ...tw`text-grey-dark` }),
    option: (styles, state) => {
      return {
        ...styles,
        boxShadow: 'none',
        background: state.isSelected
          ? sentMessage.background
          : state.isFocused
          ? chroma(sentMessage.background).alpha(0.25).css()
          : 'none',
        color:
          state.isSelected || state.isFocuesed ? sentMessage.text : state.color,
        '&:hover': {
          background:
            state.isSelected || state.isFocuesed
              ? sentMessage.background
              : chroma(sentMessage.background).alpha(0.25).css(),
          color:
            state.isSelected || state.isFocuesed ? sentMessage.text : 'black'
        }
      };
    }
  };

  return (
    <StyledSelect
      menuPlacement="top"
      styles={{ ...styles, ...styleOverrides }}
      components={{ IndicatorsContainer: () => null }}
      noOptionsMessage={() => t('fuzzy_composer_no_options_message')}
      {...props}
    />
  );
};

const FuzzyComposer = ({ content }) => {
  const { reply, setReply } = useComposer();
  const { sendMessage } = useDelivery();
  const { showBurgerIcon, setShowBurgerMenu } = useBot();

  const { t } = useTranslation('bots');

  const handleBurgerIconClick = () => {
    setShowBurgerMenu(open => !open);
  };

  const handleSelectChange = selection => {
    if (!selection) {
      return setReply('');
    }

    if (content.multi) {
      return setReply(selection.map(option => option.value).join(','));
    }

    setReply(selection.value);
  };

  const handleSubmit = () => {
    if (reply) {
      const postback = reply;
      const message = postback
        .split(',')
        .map(item => content.items.find(option => option.value === item).label)
        .join(', ');

      sendMessage(message, postback);
      setReply('');
    }
  };

  /**
   * We want React Select to manage the Input value of the select, until
   * we need to clear it.
   */
  const value = useMemo(() => {
    // Let React Select manage it's own state of the Input.
    if (reply) {
      return undefined;
    }

    // Control the Input so we can clear the value.
    return '';
  }, [reply]);

  const handleOnKeyDown = event => {
    if (content.disabled) {
      return;
    }

    if (event.keyCode === 13) {
      handleSubmit();
    }
  };

  return (
    <Container>
      <Flex xSpaceSm middle fat center pl pr tall>
        {showBurgerIcon && (
          <BurgerButton
            aria-label={t('burger_button_label')}
            onClick={handleBurgerIconClick}
          />
        )}
        <Select
          aria-label={content.placeholder}
          placeholder={content.placeholder}
          disabled={content.disabled}
          options={content.items.map(({ label, value }) => ({
            value,
            label,
            isDisabled: content.disabled
          }))}
          onChange={handleSelectChange}
          handleSubmit={handleSubmit}
          onKeyDown={handleOnKeyDown}
          isMulti={content.multi}
          value={value}
        />
        <SendButton
          aria-label={t('send_button_label')}
          disabled={content.disabled}
          onClick={handleSubmit}
        />
      </Flex>
    </Container>
  );
};

FuzzyComposer.propTypes = {
  content: PropTypes.shape({
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    multi: PropTypes.bool,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired
      })
    ).isRequired
  })
};

export default FuzzyComposer;
