import PropTypes from 'prop-types';

const StringPreview = ({ value }) => {
  if (!value) {
    return 'N/A';
  }

  return value;
};

StringPreview.propTypes = {
  value: PropTypes.string.isRequired
};

export default StringPreview;
