import { useTyping, useBelowScreenSize } from '../../../hooks/index';

const STATES = {
  LOADING: 'loading',
  TYPING: 'typing',
  HIDDEN: null
};

const getMobileAnimation = message => {
  if (message.type === 'progress') {
    return STATES.LOADING;
  }

  if (message.direction === 'toServer') {
    return STATES.LOADING;
  }

  return STATES.HIDDEN;
};

const getDesktopAnimation = (message, typing) => {
  if (typing.typing.typing) {
    return STATES.LOADING;
  }

  if (message.type === 'progress') {
    return STATES.LOADING;
  }

  if (message.direction === 'toServer') {
    return STATES.LOADING;
  }

  return STATES.TYPING;
};

const useTypingIndicatorAnimation = message => {
  const typing = useTyping();
  const mobile = useBelowScreenSize();

  if (mobile) {
    return getMobileAnimation(message);
  }

  return getDesktopAnimation(message, typing);
};

export default useTypingIndicatorAnimation;
