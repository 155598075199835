import React, { useState } from 'react';
import dayjs from 'dayjs';
import styled from 'styled-components';

import { AnimatePresence, motion } from '@ubisend/framer-motion';
import {
  InfiniteScroll,
  Flex,
  Span,
  Heading3,
  UserIcon,
  Timeline,
  Profile,
  Label,
  Placeholder
} from '@ubisend/pulse-components';
import { useQuery } from '@ubisend/pulse-hooks';

const TrainContainer = styled(motion.div)`
  ${tw`flex`}
  transform-origin: left center !important;
`;

const getInitals = user => {
  return `${user.first_name[0]}${user.last_name[0]}`;
};

const TrainingHistory = () => {
  const [page, setPage] = useState(1);
  const [trains, setTrains] = useState(null);

  const query = useQuery(['trains', { page }], {
    onSuccess: ({ data }) => {
      setTrains(trains => (trains ? trains.concat(data) : data));
    }
  });

  const handleBottomReached = () => {
    if (query.isLoading) {
      return;
    }

    if (page === query.data.meta.last_page) {
      return;
    }

    setPage(page => page + 1);
  };

  return (
    <InfiniteScroll targetParent handleBottomReached={handleBottomReached}>
      <Flex col>
        <Flex mb>
          <Heading3>History</Heading3>
        </Flex>
        {!trains && <Placeholder />}
        {trains && (
          <>
            {trains.length === 0 ||
            (trains.length === 1 && trains[0].is_training) ? (
              <Span light tinyText>
                Check back later to see training history
              </Span>
            ) : (
              <AnimatePresence>
                {trains
                  .filter(({ finished_training_at }) => finished_training_at)
                  .map((train, key) => (
                    <TrainContainer
                      layout
                      key={train.id}
                      initial={{ opacity: 0, scale: 0.75 }}
                      animate={{ opacity: 1, scale: 1 }}
                      exit={{ opacity: 0, scale: 0.75 }}
                      transition={{ ease: [0.16, 1, 0.3, 1], duration: 0.6 }}>
                      <Flex mrSm col center>
                        <UserIcon>
                          {train.trained_by
                            ? getInitals(train.trained_by)
                            : 'AT'}
                        </UserIcon>
                        {key !== trains.length - 1 && <Timeline />}
                      </Flex>
                      <Flex col>
                        <Profile col middle>
                          <Label mb={false}>
                            {train.trained_by
                              ? train.trained_by.name
                              : 'Automatically trained'}
                          </Label>
                          <Label mb={false} secondary>
                            {dayjs(train.finished_training_at).from(dayjs())}
                          </Label>
                        </Profile>
                        <Flex mb={key !== trains.length - 1}></Flex>
                      </Flex>
                    </TrainContainer>
                  ))}
              </AnimatePresence>
            )}
          </>
        )}
      </Flex>
    </InfiniteScroll>
  );
};

export default TrainingHistory;
