import { useReducer, useCallback } from 'react';

const MAX_ACTIONS = 5;

const getCoreAction = (actionName, allActions) => {
  return allActions.find(coreAction => coreAction.name === actionName);
};

const setInitialActions = (actions = [], allActions) => {
  return actions.map(action => {
    if (!action.name) {
      return { name: '', content: null };
    }

    const coreAction = getCoreAction(action.name, allActions);

    return {
      name: action.name,
      content: coreAction.setInitialContent(action.content)
    };
  });
};

const reducer = (actions, { type, index, allActions, ...params }) => {
  const updateAction = callback => {
    return actions.map((action, key) => {
      if (index === key) {
        return {
          ...action,
          ...callback(action)
        };
      }

      return action;
    });
  };

  switch (type) {
    // Edit single action
    case 'UPDATE_NAME':
      return updateAction(() => {
        const coreAction = getCoreAction(params.name, allActions);

        return {
          name: params.name,
          content: coreAction.setInitialContent()
        };
      });
    case 'UPDATE_CONTENT':
      return updateAction(({ content }) => ({
        content: { ...content, ...params.content }
      }));

    // Global action events.
    case 'ADD_ACTION':
      return actions.concat({ name: null, content: null });
    case 'DELETE_ACTION':
      return actions.filter((_, key) => index !== key);

    default:
      throw new Error(`No event defined in useActionReducer for ${type}`);
  }
};

const useActionReducer = ({ initialActions = [], allActions }) => {
  const [actions, dispatch] = useReducer(
    reducer,
    setInitialActions(initialActions, allActions)
  );

  const wrappedDispatch = useCallback(
    (params = {}) => {
      dispatch({ ...params, allActions });
    },
    [dispatch, allActions]
  );

  const valid = actions.every(
    action => {
      if (!action.name) {
        return false;
      }

      const coreAction = getCoreAction(action.name, allActions);

      return coreAction.valid(action.content);
    },
    [actions, allActions]
  );

  const canAdd = actions.length < MAX_ACTIONS;

  const canNotAdd = !canAdd;

  const form = { actions };

  return { actions, form, dispatch: wrappedDispatch, valid, canAdd, canNotAdd };
};

export default useActionReducer;
