import React from 'react';
import PropTypes from 'prop-types';

import { Flex } from '@ubisend/pulse-components';

import General from './General';
import Bubble from './Bubble';

const Carousel = ({ content, handleButtonClick, direction }) => (
  <Flex fat xScroll xSpace>
    {content.items.map((item, key) => (
      <General
        dataTestid={`carousel-image-${key}`}
        key={key}
        type="carousel"
        direction={direction}
        content={item}
        handleButtonClick={handleButtonClick}
      />
    ))}
  </Flex>
);

Carousel.propTypes = {
  content: PropTypes.shape({
    items: PropTypes.array.isRequired
  }).isRequired,
  direction: Bubble.propTypes.direction,
  handleButtonClick: PropTypes.func.isRequired
};

export default Carousel;
