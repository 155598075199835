import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { motion } from '@ubisend/framer-motion';

import SplitText from '../SplitText';
import Actions from '../Actions';

const Container = styled(motion.div)`
  ${tw`flex flex-col items-center`}
`;

const Action = ({ content }) => {
  return (
    <Container>
      <SplitText>{content.text}</SplitText>
      <Actions responses={content.responses} />
    </Container>
  );
};

Action.propTypes = {
  content: PropTypes.shape({
    text: PropTypes.string.isRequired,
    responses: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.oneOf(['url', 'postback', 'file', 'mailto', 'callto'])
          .isRequired,
        label: PropTypes.string.isRequired,
        action: PropTypes.string.isRequired
      })
    )
  }).isRequired
};

export default Action;
