import React from 'react';
import PropTypes from 'prop-types';

import { NumberFormatter } from '@ubisend/pulse-components';

const NumberPreview = ({ value }) => {
  return <NumberFormatter>{value}</NumberFormatter>;
};

NumberPreview.propTypes = {
  value: PropTypes.number.isRequired
};

export default NumberPreview;
