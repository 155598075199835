import PropTypes from 'prop-types';

import { useAuth } from '../hooks/index';

const FeatureFilter = ({ children, feature, fallback }) => {
  const { hasFeature } = useAuth();

  if (hasFeature(feature)) {
    return children;
  }

  if (fallback) {
    return fallback;
  }

  return null;
};

FeatureFilter.propTypes = {
  feature: PropTypes.string.isRequired,
  fallback: PropTypes.element
};

export default FeatureFilter;
