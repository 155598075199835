import React from 'react';
import PropTypes from 'prop-types';

import { useQuery } from '@ubisend/pulse-hooks';
import { Select } from '@ubisend/pulse-components';

const format = language => ({ value: language.id, label: language.name });

const AllLanguagesSelect = ({ value, ...props }) => {
  const { data: allLanguages, isLoading, isSuccess } = useQuery(
    'languages/all'
  );

  return (
    <Select
      isLoading={isLoading}
      options={
        isSuccess
          ? allLanguages.data.filter(language => !language.preview).map(format)
          : []
      }
      value={
        value && isSuccess
          ? format(allLanguages.data.find(language => language.id === value))
          : null
      }
      {...props}
    />
  );
};

AllLanguagesSelect.propTypes = {
  value: PropTypes.number
};

export default AllLanguagesSelect;
