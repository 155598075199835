import React from 'react';
import PropTypes from 'prop-types';

import { Flex, Span } from '@ubisend/pulse-components';
import { TemplatingTextInput } from '@ubisend/pulse-templating';

const LeaveFeedbackContent = ({ content, onChange }) => {
  const handleFeedbackChange = feedback => {
    onChange({ feedback });
  };

  return (
    <Flex xSpace fat center>
      <Span>using</Span>
      <Flex col fat>
        <TemplatingTextInput
          id="feedback"
          placeholder="Feedback"
          value={content.feedback}
          onChange={handleFeedbackChange}
        />
      </Flex>
    </Flex>
  );
};

LeaveFeedbackContent.propTypes = {
  onChange: PropTypes.func.isRequired,
  content: PropTypes.shape({
    feedback: PropTypes.string.isRequired
  }).isRequired
};

export default LeaveFeedbackContent;
