import NumberDefaultValueInput from './NumberDefaultValueInput';
import NumberPreview from './NumberPreview';

const number = {
  key: 'Number',
  name: 'Number',
  default_value: 0,
  DefaultValueInput: NumberDefaultValueInput,
  Preview: NumberPreview
};

export default number;
