import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { useComposer, useDelivery } from '../../../../../hooks/index';
import { isValidMessage } from '../../../../../utilities/index';
import {
  MAX_MESSAGE_LENGTH,
  MIN_MESSAGE_LIMIT
} from '../../../../../constants';
import {
  Container,
  LeftSection,
  ComposerButton,
  Airplane,
  Input,
  Label,
  labelAnimations
} from './MessageComposer';

const isValid = message => {
  return isValidMessage(message, {
    min: MIN_MESSAGE_LIMIT,
    max: MAX_MESSAGE_LENGTH
  });
};

const NumericComposer = ({ content: passedContent }) => {
  const [inputActive, setInputActive] = useState(false);

  const { t } = useTranslation('full_page');

  const content = passedContent || {
    placeholder: t('numeric_composer_label'),
    disabled: false
  };

  const { reply, setReply } = useComposer();
  const { sendMessage } = useDelivery();

  const handleOnChange = event => {
    const value = event.target.value;

    if (value.length >= MAX_MESSAGE_LENGTH) {
      return;
    }

    setReply(value);
  };

  const handleNewReply = () => {
    const validatedMessage = isValid(reply);

    if (!validatedMessage) {
      return;
    }

    sendMessage(validatedMessage);
    setReply('');
  };

  const handleOnKeyDown = event => {
    if (event.keyCode === 13) {
      handleNewReply();
    }
  };

  const handleBlur = () => {
    if (inputActive && reply.length === 0) {
      setInputActive(false);
    }
  };

  const handleFocus = () => {
    if (!inputActive) {
      setInputActive(true);
    }
  };

  return (
    <Container>
      <LeftSection htmlFor="numeric-composer">
        <Label
          htmlFor="numeric-composer"
          initial="expanded"
          variants={labelAnimations}
          animate={inputActive ? 'minimised' : 'expanded'}>
          {content.placeholder}
        </Label>
        <Input
          type="number"
          id="numeric-composer"
          value={reply}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onChange={handleOnChange}
          onKeyDown={handleOnKeyDown}
          disabled={content.disabled}
        />
      </LeftSection>
      <ComposerButton
        aria-label={t('send_button_label')}
        disabled={!isValid(reply)}
        onClick={handleNewReply}>
        <Airplane />
      </ComposerButton>
    </Container>
  );
};

NumericComposer.propTypes = {
  content: PropTypes.shape({
    placeholder: PropTypes.string.isRequired,
    disabled: PropTypes.bool.isRequired
  }),
  onBlur: PropTypes.func,
  onFocus: PropTypes.func
};

export default NumericComposer;
