import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { Flex, Button, ToggleButton, Span } from '@ubisend/pulse-components';
import {
  useConditionalTypes,
  useConditional,
  ConditionalSelect,
  SubjectSelect,
  CaseSensitiveToggle,
  showCondtionals,
  isDateTimeType,
  Condition
} from '@ubisend/pulse-conditionals';
import { TemplatingTextInput } from '@ubisend/pulse-templating';

const VariableConditional = ({
  index,
  labelPrefix,
  type,
  condition,
  target,
  case_sensitive,
  flipped,
  subject
}) => {
  const types = useConditionalTypes();
  const {
    dispatch,
    conditionals,
    match,
    handleConditionalChange,
    handleConditionChange,
    handleConditionalDelete,
    handleSubjectChange,
    handleCaseToggle,
    handleFlippedToggle,
    getSubjectValue
  } = useConditional();

  const label = (string, index) => `${labelPrefix}-${string}-${index}`;

  const conditionalType = useMemo(() => {
    const conditional = types.find(({ name }) => name === type);

    if (!conditional) {
      return null;
    }

    return conditional.type;
  }, [types, type]);

  const handleTargetChange = value => {
    dispatch({ type: 'CHANGE_TARGET', newTarget: value, index });
  };

  return (
    <Flex col ySpace>
      <Flex xSpace center>
        <Span uppercase style={{ minWidth: '4rem', textAlign: 'right' }}>
          {index === 0 && 'if'}
          {index !== 0 && match === 'any' && 'Or'}
          {index !== 0 && match === 'all' && 'And'}
        </Span>
        <ToggleButton
          on={flipped}
          onChange={handleFlippedToggle(index)}
          aria-label={label('flipped', index)}>
          not
        </ToggleButton>
        <Flex col fat ySpace border pad>
          <Flex xSpace center fat>
            <SubjectSelect
              aria-label={label('subject', index)}
              value={getSubjectValue({ conditionalType, type, subject })}
              onChange={handleSubjectChange(index)}
            />
            <Flex col fat>
              <TemplatingTextInput
                aria-label={label('target', index)}
                placeholder="{{my_variable}}"
                value={target}
                onChange={handleTargetChange}
              />
            </Flex>
          </Flex>
          <Flex>
            {(!type || showCondtionals(conditionalType)) && (
              <Flex fat xSpace growChildren>
                <CaseSensitiveToggle
                  aria-label={label('case-sensitive', index)}
                  index={index}
                  value={case_sensitive}
                  onChange={handleCaseToggle(index)}
                />
                <ConditionalSelect
                  aria-label={label('conditional', index)}
                  value={type}
                  onChange={handleConditionalChange(index)}
                />
                <Condition
                  aria-label={label('condition', index)}
                  name={conditionalType}
                  value={condition}
                  onChange={handleConditionChange(index)}
                />
              </Flex>
            )}

            {isDateTimeType(conditionalType) && (
              <Condition
                aria-label={label('condition', index)}
                name={conditionalType}
                value={condition}
                onChange={handleConditionChange(index)}
              />
            )}
          </Flex>
        </Flex>
        <Flex grow right>
          <Button
            variant="secondary"
            colour="danger"
            icon="trash"
            disabled={conditionals.length <= 1}
            onClick={handleConditionalDelete(index)}>
            Delete
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );
};

VariableConditional.propTypes = {
  index: PropTypes.number.isRequired,
  labelPrefix: PropTypes.string.isRequired,
  subject: PropTypes.shape({
    type: PropTypes.string
  }).isRequired,
  type: PropTypes.string,
  condition: PropTypes.string,
  target: PropTypes.string,
  case_sensitive: PropTypes.bool,
  flipped: PropTypes.bool
};

export default VariableConditional;
