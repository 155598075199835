import React, { useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { motion, useAnimation } from '@ubisend/framer-motion';

const dotSize = 12;
const distance = dotSize * 4;

const Circle = styled(motion.div)`
  ${tw`inline-block bg-white mx-1`}
  margin-right: ${dotSize}px;
  border-radius: ${dotSize / 2}px;
  width: ${dotSize}px;
  height: ${dotSize}px;
`;

const Container = styled(motion.div)`
  ${tw`flex pb-2 md:pb-4 items-center justify-center`}
  height: ${distance}px;
  margin-top: -2rem;
`;

const loadingAnim = index => ({
  y: [null, -distance, 0],
  height: [null, distance / 3, dotSize, dotSize, distance / 3, dotSize],
  transition: {
    y: {
      repeat: Infinity,
      repeatType: 'loop',
      duration: 2,
      ease: [0.87, 0, 0.13, 1],
      delay: index * 0.075
    },
    height: {
      repeat: Infinity,
      repeatType: 'loop',
      duration: 2,
      ease: [0.25, 1, 0.5, 1],
      times: [0.19, 0.275, 0.5, 0.69, 0.775, 1],
      delay: index * 0.075
    }
  }
});

const typingAnim = index => ({
  height: new Array(9)
    .fill(0)
    .map((_, i) => {
      const random = Math.random() * dotSize * 4;
      return i % 2 !== 0 ? dotSize * 2 + random : dotSize;
    })
    .concat([dotSize]),
  transition: {
    delay: index * 0.1,
    duration: 1,
    ease: 'linear'
  }
});

const animations = {
  loading: loadingAnim,
  typing: typingAnim,
  idle: {}
};

const TypingIndicator = ({ animation = 'loading' }) => {
  const controls = useAnimation();

  useEffect(() => {
    const toggleAnimation = async () => {
      await controls.stop();
      await controls.start({ y: 0, height: dotSize });
      await controls.start(animations[animation]);
    };
    toggleAnimation();
  }, [animation, controls]);

  return (
    <Container layout data-testid="typing-indicator">
      {[0, 1, 2, 3].map(i => (
        <Circle
          layout
          animate={controls}
          key={i}
          custom={i}
          transition={{ duration: 0.2 }}
        />
      ))}
    </Container>
  );
};

TypingIndicator.propTypes = {
  animation: PropTypes.oneOf(['loading', 'typing', 'idle'])
};

export default TypingIndicator;
