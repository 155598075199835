import CreateTicketContent from './CreateTicketContent';

const createTicket = {
  name: 'Create ticket',
  Content: CreateTicketContent,
  setInitialContent: (content = {}) => {
    return {
      name: content.name || '',
      description: content.description || '',
      tags: content.tags || []
    };
  },
  valid: content => {
    return Boolean(content.name && content.description);
  }
};

export default createTicket;
