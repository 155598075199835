import React, { useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';

import { useMutation, useQueryClient } from '@ubisend/pulse-hooks';
import {
  Flex,
  Panel,
  Checkbox,
  Button,
  Explainer,
  Divider,
  useNotification
} from '@ubisend/pulse-components';

import { updateExportSections } from '../api/index';

const NlpExportSettings = ({ item }) => {
  const [sections, setSections] = useState([]);

  useEffect(() => {
    setSections(
      item.sections.filter(section => section.is_searchable).map(({ id }) => id)
    );
  }, [item.sections]);

  const allSelected = useMemo(() => {
    if (!sections) {
      return false;
    }

    return sections.length === item.sections.length;
  }, [sections, item]);

  const { showSuccess } = useNotification();
  const queryClient = useQueryClient();

  const { mutate: updateSections } = useMutation(updateExportSections, {
    onSuccess: () => {
      queryClient.invalidateQueries('imports');

      showSuccess('Successfully updated NLP section settings');
    }
  });

  const handleSelect = id => {
    if (sections.includes(id)) {
      setSections(sections.filter(section => section !== id));
    } else {
      setSections(sections.concat(id));
    }
  };

  const handleSelectAll = () => {
    if (allSelected) {
      setSections([]);
    } else {
      setSections(item.sections.map(({ id }) => id));
    }
  };

  const handleSubmit = async e => {
    e.preventDefault();

    await updateSections({
      id: item.id,
      section_ids: sections
    });
  };

  const handleReset = () => {
    setSections(
      item.sections.filter(section => section.is_searchable).map(({ id }) => id)
    );
  };

  return (
    <form onSubmit={handleSubmit}>
      <Flex mt>
        <Panel header={'Select content for NLP'}>
          <Explainer>
            Content selected here will be indexed for use within your chatbot,
            meaning it can be searched by your users.
          </Explainer>
          <Flex center mt>
            <Checkbox
              checked={allSelected}
              onChange={handleSelectAll}
              label="Select All"
            />
          </Flex>
          <Divider fullWidth />
          <Flex
            shiftUp
            shiftDown
            shiftLeft
            col
            ySpace
            pad
            style={{ maxHeight: '16rem', overflowY: 'auto' }}>
            {item.sections.map(({ id, content }) => (
              <Flex center mt key={id}>
                <Checkbox
                  checked={sections && sections.includes(id)}
                  onChange={() => handleSelect(id)}
                  label={content.name}
                />
              </Flex>
            ))}
          </Flex>
          <Divider fullWidth />
          <Flex mt right xSpace>
            <Button variant="secondary" onClick={handleReset}>
              Reset
            </Button>
            <Button type="submit" icon="save">
              Save
            </Button>
          </Flex>
        </Panel>
      </Flex>
    </form>
  );
};

NlpExportSettings.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number,
    sections: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        content: PropTypes.string,
        is_searchable: PropTypes.bool
      })
    )
  }).isRequired
};

export default NlpExportSettings;
