import { useReducer } from 'react';
import dayjs from 'dayjs';

const TYPES = {
  // Global actions
  ADD_HOURS: 'ADD_HOURS',

  // Per hours actions
  DELETE_HOURS: 'DELETE_HOURS',
  UPDATE_OPEN_DAY: 'UPDATE_OPEN_DAY',
  UPDATE_OPEN_TIME: 'UPDATE_OPEN_TIME',
  UPDATE_CLOSE_DAY: 'UPDATE_CLOSE_DAY',
  UPDATE_CLOSE_TIME: 'UPDATE_CLOSE_TIME'
};

const reducer = (state, { type, ...params }) => {
  const updateActiveHour = callback => {
    return state.map(activeHour => {
      if (activeHour.id === params.id) {
        return {
          ...activeHour,
          ...callback(activeHour)
        };
      }

      return activeHour;
    });
  };

  switch (type) {
    // Global actions
    case TYPES.ADD_HOURS:
      return state.concat({
        id: Math.random(),
        open_day: null,
        open_time: '09:00:00',
        close_day: null,
        close_time: '17:00:00'
      });

    // Per hours actions
    case TYPES.DELETE_HOURS:
      return state.filter(hours => hours.id !== params.id);
    case TYPES.UPDATE_OPEN_DAY:
      return updateActiveHour(() => {
        return { open_day: params.day };
      });
    case TYPES.UPDATE_OPEN_TIME:
      return updateActiveHour(() => {
        return { open_time: params.time };
      });
    case TYPES.UPDATE_CLOSE_DAY:
      return updateActiveHour(() => {
        return { close_day: params.day };
      });
    case TYPES.UPDATE_CLOSE_TIME:
      return updateActiveHour(() => {
        return { close_time: params.time };
      });
    default:
      throw new Error(`No event defined in useActiveHoursReducer for ${type}`);
  }
};

const TIMEZONE_OFFSET = new Date().getTimezoneOffset();

const DAYS = {
  monday: '01-01-1996',
  tuesday: '02-01-1996',
  wednesday: '03-01-1996',
  thursday: '04-01-1996',
  friday: '05-01-1996',
  saturday: '06-01-1996',
  sunday: '07-01-1996'
};

const getDate = (day, time) => {
  return dayjs(`${DAYS[day]} ${time}`, 'DD-MM-YYYY HH:mm:ss');
};

const convertToLocalTime = time => {
  const open = getDate(time.open_day, time.open_time).subtract(
    TIMEZONE_OFFSET,
    'minutes'
  );
  const close = getDate(time.close_day, time.close_time).subtract(
    TIMEZONE_OFFSET,
    'minutes'
  );

  return {
    open_day: open.format('dddd').toLowerCase(),
    open_time: open.format('HH:mm:ss'),
    close_day: close.format('dddd').toLowerCase(),
    close_time: close.format('HH:mm:ss')
  };
};

const convertToUtcTime = time => {
  const open = getDate(time.open_day, time.open_time).add(
    TIMEZONE_OFFSET,
    'minutes'
  );
  const close = getDate(time.close_day, time.close_time).add(
    TIMEZONE_OFFSET,
    'minutes'
  );

  return {
    open_day: open.format('dddd').toLowerCase(),
    open_time: open.format('HH:mm:ss'),
    close_day: close.format('dddd').toLowerCase(),
    close_time: close.format('HH:mm:ss')
  };
};

const useActiveHoursReducer = initialActiveHours => {
  const [state, dispatch] = useReducer(
    reducer,
    initialActiveHours.map(hours => {
      const time = convertToLocalTime(hours);

      return {
        id: hours.id,
        ...time
      };
    })
  );

  const form = state.map(hours => {
    const time = convertToUtcTime(hours);

    return {
      open_day: time.open_day,
      open_time: time.open_time,
      close_day: time.close_day,
      close_time: time.close_time
    };
  });

  const valid = state.every(hours => {
    return Boolean(
      hours.open_day && hours.open_time && hours.close_day && hours.close_time
    );
  });

  return {
    activeHours: state,
    dispatch,
    form,
    valid,
    TYPES
  };
};

export default useActiveHoursReducer;
