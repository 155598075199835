const getParam = (name, search) => {
  return new URLSearchParams(search).get(name);
};

const useQuerySearch = (location, param) => {
  const result = getParam(param, location.search);

  return result;
};

export default useQuerySearch;
