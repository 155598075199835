import GeneratePasswordResetTokenContent from './GeneratePasswordResetTokenContent';

const generatePasswordResetToken = {
  name: 'Generate password reset token',
  Content: GeneratePasswordResetTokenContent,
  setInitialContent: (content = {}) => {
    return {
      email: content.email || ''
    };
  },
  valid: content => {
    return Boolean(content.email);
  }
};

export default generatePasswordResetToken;
