import React from 'react';
import PropTypes from 'prop-types';

import { Flex, Label } from '@ubisend/pulse-components';
import { PermissionFilter } from '@ubisend/pulse-auth';
import { GroupSelect } from '@ubisend/pulse-groups';

import AssigneeSelect from './AssigneeSelect';
import TicketTypeSelect from './TicketTypeSelect';
import TicketStatusSelect from './TicketStatusSelect';

const TicketFilters = ({
  filters,
  handleStatusesChange,
  handleTypesChange,
  handleAssigneesChange,
  handleGroupsChange
}) => (
  <Flex fat col style={{ width: '20rem' }}>
    <Flex col fat mb>
      <Label htmlFor="status">Status</Label>
      <TicketStatusSelect
        id="status"
        value={filters.statuses}
        onChange={handleStatusesChange}
      />
    </Flex>
    <Flex col fat mb>
      <Label htmlFor="type">Types</Label>
      <TicketTypeSelect
        id="type"
        value={filters.ticket_types}
        onChange={handleTypesChange}
      />
    </Flex>
    <Flex col fat mb>
      <Label htmlFor="tags">Tags</Label>
      <GroupSelect
        id="tags"
        for="tickets"
        value={filters.group_ids}
        onChange={handleGroupsChange}
        placeholder="Filter with tags"
      />
    </Flex>
    <PermissionFilter can="view agents">
      <Flex col fat>
        <Label htmlFor="assignee_ids">Assignees</Label>
        <AssigneeSelect
          isMulti
          id="assignee_ids"
          value={filters.assignee_ids}
          onChange={handleAssigneesChange}
        />
      </Flex>
    </PermissionFilter>
  </Flex>
);

TicketFilters.propTypes = {
  filters: PropTypes.shape({
    assignee_ids: PropTypes.arrayOf(PropTypes.number),
    group_ids: PropTypes.arrayOf(PropTypes.number),
    ticket_types: PropTypes.arrayOf(PropTypes.string),
    statuses: PropTypes.arrayOf(PropTypes.string)
  }),
  handleStatusesChange: PropTypes.func.isRequired,
  handleTypesChange: PropTypes.func.isRequired,
  handleAssigneesChange: PropTypes.func.isRequired,
  handleGroupsChange: PropTypes.func.isRequired
};
export default TicketFilters;
