import SetLocationContent from './SetLocationContent';

const setLocation = {
  name: 'Set subscriber location',
  Content: SetLocationContent,
  setInitialContent: (content = {}) => {
    return {
      location: content.location || ''
    };
  },
  valid: content => {
    return Boolean(content.location);
  }
};

export default setLocation;
