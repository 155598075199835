import Logout from './Logout';
import UserCircle from './UserCircle';
import User from './User';
import CheveronRight from './CheveronRight';
import CheveronLeft from './CheveronLeft';
import CheveronUp from './CheveronUp';
import CheveronDown from './CheveronDown';
import Chat from './Chat';
import EmojiSad from './EmojiSad';
import Template from './Template';
import UserGroup from './UserGroup';
import Users from './Users';
import LightningBolt from './LightningBolt';
import Link from './Link';
import ColorSwatch from './ColorSwatch';
import Pencil from './Pencil';
import Cog from './Cog';
import BookOpen from './BookOpen';
import Eye from './Eye';
import EyeOff from './EyeOff';
import XCircle from './XCircle';
import Check from './Check';
import X from './X';
import CheckCircle from './CheckCircle';
import ExclamationCircle from './ExclamationCircle';
import Adjustments from './Adjustments';
import ChatAlt from './ChatAlt';
import Speakerphone from './Speakerphone';
import Archive from './Archive';
import ExternalLink from './ExternalLink';
import Photograph from './Photograph';
import Document from './Document';
import DocumentReport from './DocumentReport';
import Download from './Download';
import DotsHorizontal from './DotsHorizontal';
import Clock from './Clock';
import Ticket from './Ticket';
import InformationCircle from './InformationCircle';
import QuestionMarkCircle from './QuestionMarkCircle';
import BadgeCheck from './BadgeCheck';
import Inbox from './Inbox';
import InboxIn from './InboxIn';
import Globe from './Globe';
import Tag from './Tag';
import CreditCard from './CreditCard';
import ChartBar from './ChartBar';
import Table from './Table';
import BulletPoints from './BulletPoints';
import NumberedList from './NumberedList';
import channelIcons from './channel/index';
import Flag from './Flag';
import SwitchHorizontal from './SwitchHorizontal';
import Plus from './Plus';
import MenuAlt4 from './MenuAlt4';
import PlusCircle from './PlusCircle';
import Search from './Search';
import Key from './Key';
import Mail from './Mail';
import Exclamation from './Exclamation';
import Variable from './Variable';
import PaperClip from './PaperClip';
import LockOpen from './LockOpen';
import LockClosed from './LockClosed';

const solidIcons = {
  ...channelIcons,
  plusCircle: PlusCircle,
  logout: Logout,
  userCircle: UserCircle,
  userGroup: UserGroup,
  users: Users,
  user: User,
  cheveronRight: CheveronRight,
  cheveronLeft: CheveronLeft,
  cheveronUp: CheveronUp,
  cheveronDown: CheveronDown,
  emojiSad: EmojiSad,
  template: Template,
  chat: Chat,
  lightningBolt: LightningBolt,
  link: Link,
  colorSwatch: ColorSwatch,
  pencil: Pencil,
  cog: Cog,
  bookOpen: BookOpen,
  eye: Eye,
  xCircle: XCircle,
  check: Check,
  x: X,
  checkCircle: CheckCircle,
  exclamationCircle: ExclamationCircle,
  adjustments: Adjustments,
  chatAlt: ChatAlt,
  speakerphone: Speakerphone,
  archive: Archive,
  eyeOff: EyeOff,
  externalLink: ExternalLink,
  photograph: Photograph,
  document: Document,
  documentReport: DocumentReport,
  download: Download,
  dotsHorizontal: DotsHorizontal,
  clock: Clock,
  ticket: Ticket,
  informationCircle: InformationCircle,
  questionMarkCircle: QuestionMarkCircle,
  badgeCheck: BadgeCheck,
  inbox: Inbox,
  inboxIn: InboxIn,
  globe: Globe,
  tag: Tag,
  creditCard: CreditCard,
  chartBar: ChartBar,
  table: Table,
  bulletPoints: BulletPoints,
  numberedList: NumberedList,
  flag: Flag,
  switchHorizontal: SwitchHorizontal,
  plus: Plus,
  menuAlt4: MenuAlt4,
  search: Search,
  key: Key,
  mail: Mail,
  exclamation: Exclamation,
  variable: Variable,
  paperClip: PaperClip,
  lockOpen: LockOpen,
  lockClosed: LockClosed
};

export default solidIcons;
