import DeleteSubscriberContent from './DeleteSubscriberContent';

const deleteSubscriber = {
  name: 'Delete subscriber',
  Content: DeleteSubscriberContent,
  setInitialContent: () => {
    return {};
  },
  valid: () => {
    return true;
  }
};

export default deleteSubscriber;
