import React from 'react';

import { PageWrapper, FilterMenu } from '@ubisend/pulse-components';

import { Messages, Filter } from '../Components/index';
import { useFilters } from '../hooks/index';

const History = () => {
  const filters = useFilters({ id: 'history' });

  return (
    <PageWrapper
      header="History"
      subheader="View previously trained messages"
      actions={
        <FilterMenu
          {...filters.filterMenuProps}
          position={FilterMenu.POSITIONS.LEFT}>
          <Filter {...filters.filterProps} />
        </FilterMenu>
      }>
      <Messages
        filters={filters.filters}
        pagination={filters.pagination}
        order={filters.order}
        query="training/trained"
        multi={false}
        showLink={false}
        showActions={false}
      />
    </PageWrapper>
  );
};

export default History;
