import SetCredentialsContent from './SetCredentialsContent';

const setCredentials = {
  name: 'Set subscriber credentials',
  Content: SetCredentialsContent,
  setInitialContent: (content = {}) => {
    return {
      email: content.email || '',
      password: content.password || ''
    };
  },
  valid: content => {
    return Boolean(content.email && content.password);
  }
};

export default setCredentials;
