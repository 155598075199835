import React from 'react';
import PropTypes from 'prop-types';

import { Flex, Span } from '@ubisend/pulse-components';
import { TemplatingTextInput } from '@ubisend/pulse-templating';

const SetLanguageContent = ({ content, onChange }) => {
  const handleLanguageChange = language => {
    onChange({ language });
  };

  return (
    <Flex xSpace fat center>
      <Span>using</Span>
      <Flex col fat>
        <TemplatingTextInput
          id="language"
          placeholder="English"
          value={content.language}
          onChange={handleLanguageChange}
        />
      </Flex>
    </Flex>
  );
};

SetLanguageContent.propTypes = {
  onChange: PropTypes.func.isRequired,
  content: PropTypes.shape({
    language: PropTypes.string.isRequired
  }).isRequired
};

export default SetLanguageContent;
