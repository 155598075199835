import React from 'react';
import PropTypes from 'prop-types';

import { useQuery } from '@ubisend/pulse-hooks';
import { Select } from '@ubisend/pulse-components';

const SenderSelect = ({ value, onChange, ...rest }) => {
  const { data, isLoading } = useQuery('emails/trusted-senders');

  return (
    <Select
      isLoading={isLoading}
      value={value && { label: value, value }}
      options={
        data
          ? data.data.map(({ from_address }) => ({
              label: from_address,
              value: from_address
            }))
          : []
      }
      onChange={onChange}
      aria-label="From suffix"
      {...rest}
    />
  );
};

SenderSelect.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired
};

export default SenderSelect;
