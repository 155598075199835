import React from 'react';
import PropTypes from 'prop-types';

import { useAuth } from '../hooks/index';
import GlobalRoleDebug from './GlobalRoleDebug';
import { DEBUG_ROLES } from '../constants';

const GlobalRoleFilter = ({ children, for: role, fallback }) => {
  const { hasGlobalRole } = useAuth();

  if (DEBUG_ROLES) {
    return (
      <GlobalRoleDebug role={role} hasGlobalRole={hasGlobalRole(role)}>
        {children}
      </GlobalRoleDebug>
    );
  }

  if (hasGlobalRole(role)) {
    return children;
  }

  if (fallback) {
    return fallback;
  }

  return null;
};

GlobalRoleFilter.propTypes = {
  for: PropTypes.string.isRequired,
  fallback: PropTypes.element
};

export default GlobalRoleFilter;
