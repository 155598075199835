import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { AnimateSharedLayout } from '@ubisend/framer-motion';

import TabButton from '../Button/TabButton';

const Container = styled.div`
  ${tw`inline-flex items-center rounded-sm cursor-pointer bg-grey-light`}
`;

// Neutral denotes whether the toggle options both use the same colour when active
// (pink by default) and whether to render options at all times.
const Toggle = ({
  handleToggleChange,
  on,
  options = ['Off', 'On'],
  title,
  ...props
}) => (
  <Container {...props} onClick={handleToggleChange}>
    <AnimateSharedLayout>
      {/* Off Button */}
      <TabButton aria-label={`${title}-toggle-off`} active={!on}>
        {options[0]}
      </TabButton>
      {/* On Button */}
      <TabButton aria-label={`${title}-toggle-on`} active={on}>
        {options[1]}
      </TabButton>
    </AnimateSharedLayout>
  </Container>
);

Toggle.propTypes = {
  handleToggleChange: PropTypes.func.isRequired,
  on: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.Number),
  title: PropTypes.string
};

export default Toggle;
