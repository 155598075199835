import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { useQuery } from '@ubisend/pulse-hooks';
import { Select } from '@ubisend/pulse-components';

const format = ({ id, name }) => ({ value: id, label: name });

const LookupSelect = ({ value, sheet, ...props }) => {
  const { data, isLoading, isSuccess } = useQuery('spreadsheets');

  const disabled = !sheet;

  const lookups = useMemo(() => {
    if (!sheet || !isSuccess) {
      return null;
    }

    return data.data.find(({ id }) => id === sheet).lookups;
  }, [data, isSuccess, sheet]);

  const getExistingLookup = () => {
    const lookup = lookups.find(sheet => sheet.id === value);

    if (!lookup) {
      return null;
    }

    return format(lookup);
  };

  return (
    <Select
      isLoading={isLoading}
      isDisabled={disabled}
      placeholder={'My Lookup'}
      options={lookups ? lookups.map(format) : []}
      value={lookups && value && getExistingLookup()}
      {...props}
    />
  );
};

LookupSelect.propTypes = {
  value: PropTypes.string,
  sheet: PropTypes.number
};

export default LookupSelect;
