import React, { useMemo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Flex } from '@ubisend/pulse-components';

import {
  useTypingIndicatorAnimation,
  useHasMediaMessage,
  useHasContentMessage
} from '../../../hooks/index';
import {
  Message,
  TypingIndicator,
  MediaMessageSlideIn,
  ContentMessageSlideIn,
  Button
} from '../../../Components/index';
import {
  GroupProgress,
  ProgressBar,
  ProgressNumber,
  ButtonContainer,
  ProgressBarContainer,
  Container,
  Inner,
  BackArrow,
  ForwardArrow,
  MessageNavLabel,
  MessageContainer
} from '../../Messages';

const MessagePreview = ({ group }) => {
  const [messageIndex, setMessageIndex] = useState(0);
  const [active, setActive] = useState(0);

  const { t } = useTranslation('full_page');

  const message = useMemo(() => {
    return group[messageIndex];
  }, [messageIndex, group]);

  const hasNextMessage = useMemo(() => {
    return messageIndex < group.length - 1;
  }, [messageIndex, group.length]);

  const hasPreviousMessage = useMemo(() => {
    return messageIndex !== 0;
  }, [messageIndex]);

  const handleNextMessage = () => {
    setMessageIndex(messageIndex + 1);
  };

  const handlePreviousMessage = () => {
    setMessageIndex(messageIndex - 1);
  };

  const groupProgress = useMemo(() => {
    return (messageIndex + 1) / group.length;
  }, [messageIndex, group.length]);

  const hasMediaMessage = useHasMediaMessage(message);
  const hasContentMessage = useHasContentMessage(message);
  const animation = useTypingIndicatorAnimation(message);

  useEffect(() => {
    if (message) {
      setActive(0);
    }
  }, [message]);

  return (
    <>
      <Container>
        <Inner>
          {animation && (
            <Flex middle mb>
              <TypingIndicator animation={animation} />
            </Flex>
          )}
          {message && message.direction === 'toClient' && (
            <MessageContainer>
              <Message
                key={message.id}
                index={active}
                message={message}
                handleButtonClick={() => {}}
              />
            </MessageContainer>
          )}
        </Inner>
        <ButtonContainer
          layout="position"
          hasContentMessage={hasContentMessage}
          MediaMessageSlideIn={MediaMessageSlideIn}>
          <Button
            style={{ marginRight: '1.5rem' }}
            disabled={!hasPreviousMessage}
            animate={{ visibility: hasPreviousMessage ? 'visible' : 'hidden' }}
            onClick={handlePreviousMessage}>
            <BackArrow />{' '}
            <MessageNavLabel>
              {t('message_group_preview_back_button')}
            </MessageNavLabel>
          </Button>
          {group.length > 1 && (
            <GroupProgress>
              <ProgressNumber>{messageIndex + 1}</ProgressNumber>
              <ProgressBarContainer>
                <ProgressBar layout width={`${groupProgress * 100}%;`} />
              </ProgressBarContainer>
              <ProgressNumber>{group.length}</ProgressNumber>
            </GroupProgress>
          )}
          <Button
            style={{ marginLeft: '1.5rem' }}
            disabled={!hasNextMessage}
            animate={{ visibility: hasNextMessage ? 'visible' : 'hidden' }}
            onClick={handleNextMessage}>
            <MessageNavLabel>
              {t('message_group_preview_next_button')}
            </MessageNavLabel>{' '}
            <ForwardArrow />
          </Button>
        </ButtonContainer>
        <MediaMessageSlideIn
          visible={hasMediaMessage}
          message={message}
          active={active}
          setActive={setActive}
        />
      </Container>
      <ContentMessageSlideIn message={message} />
    </>
  );
};

MessagePreview.propTypes = {
  group: PropTypes.array
};

export default MessagePreview;
