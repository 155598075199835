import styled from 'styled-components';

import { motion } from '@ubisend/framer-motion';

import { spacing, text } from '../styles';

const Heading2 = styled(motion.h2)`
  ${tw`m-0 font-semibold`}
  ${spacing}
  ${text}
`;

export default Heading2;
