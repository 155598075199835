import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { motion } from '@ubisend/framer-motion';
import { breakpoints } from '@ubisend/pulse-components';

import Image from './Image';

const Dots = styled.div`
  ${tw`flex z-10 w-full justify-center`}
  @media (min-width: ${breakpoints.md}px) {
    bottom: 6rem;
  }
`;

const Dot = styled(motion.div)`
  ${tw`rounded-full bg-white w-3 h-3 mx-2 cursor-pointer`}
`;

const Container = styled(motion.div)`
  ${tw`w-full flex mb-4 justify-center`}
`;

const StyledImage = styled(Image)`
  ${tw`w-full md:w-auto`}
`;

const Slider = ({ active, setActive, content }) => {
  const handleImageClick = index => () => {
    const imageIsActive = active === index;

    if (imageIsActive && index === 0) {
      return;
    }

    setActive(imageIsActive ? index - 1 : index);
  };

  return (
    <>
      <Container>
        <StyledImage
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          src={content.items[active].image}
          onClick={handleImageClick(active)}
          alt={content.items[active].image_description || 'Image message'}
        />
      </Container>
      <Dots>
        {content.items.map((_, index) => (
          <Dot
            initial={{ opacity: 0.5 }}
            animate={{ opacity: active === index ? 1 : 0.5 }}
            key={index}
            onClick={() => setActive(index)}
          />
        ))}
      </Dots>
    </>
  );
};

Slider.propTypes = {
  content: PropTypes.shape({
    text: PropTypes.string,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        image: PropTypes.string.isRequired,
        image_description: PropTypes.string
      }).isRequired
    )
  }).isRequired,
  active: PropTypes.number,
  setActive: PropTypes.func
};

export default Slider;
