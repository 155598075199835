import React, { useState } from 'react';

import { AnimatePresence } from '@ubisend/framer-motion';
import {
  Button,
  PanelSlider,
  useNotification,
  useModal,
  Divider,
  Flex,
  Span
} from '@ubisend/pulse-components';
import { PermissionFilter } from '@ubisend/pulse-auth';
import { useMutation, useQuery, useQueryClient } from '@ubisend/pulse-hooks';

import { train } from '../api/index';
import TrainingHistory from './TrainingHistory';
import TrainingSettings from './TrainingSettings';
import { createTrainingMessage } from '../utils/index';

const Train = () => {
  const [showHistory, setShowHistory] = useState(false);

  const { showSuccess } = useNotification();
  const { showModal, hideModal } = useModal();

  const queryClient = useQueryClient();
  const query = useQuery('trains/latest');
  const mutation = useMutation(train, {
    onSuccess: () => {
      queryClient.invalidateQueries('trains/latest');
    }
  });

  const handleTrain = () => {
    showModal({
      header: 'Train your chatbot',
      body: `Are you sure you want to train your chatbot? This may take a couple of minutes.`,
      handleConfirm: async () => {
        try {
          await mutation.mutateAsync();
          showSuccess(
            'Your chatbot is now training, this may take a couple of minutes.'
          );
        } finally {
          hideModal();
        }
      }
    });
  };

  const handleHistoryView = () => {
    setShowHistory(true);
  };

  const isTraining = query.isSuccess ? query.data.data.is_training : false;

  return (
    <>
      <PermissionFilter can="create training history">
        <Button onClick={handleHistoryView} icon="chip">
          Train
        </Button>
      </PermissionFilter>
      <AnimatePresence>
        {showHistory && (
          <PanelSlider
            header="Train"
            width="40vw"
            handleHide={() => setShowHistory(false)}>
            <Flex center between>
              <Button
                variant="primary"
                icon="cog"
                onClick={handleTrain}
                disabled={query.isLoading}
                loading={isTraining}>
                {isTraining ? 'Training' : 'Train now'}
              </Button>
              {query.isSuccess && (
                <Span tinyText light>
                  {createTrainingMessage(query.data.data)}
                </Span>
              )}
            </Flex>
            <Divider fullWidth />
            <PermissionFilter can="edit training settings">
              <TrainingSettings />
              <Divider fullWidth />
            </PermissionFilter>
            <PermissionFilter can="view training history">
              <TrainingHistory />
            </PermissionFilter>
          </PanelSlider>
        )}
      </AnimatePresence>
    </>
  );
};

export default Train;
