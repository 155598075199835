import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { motion, AnimatePresence } from '@ubisend/framer-motion';

import { POSITIONS } from '../../hooks/useTooltip';

const animStates = {
  [POSITIONS.LEFT]: {
    initial: { opacity: 0, x: -20 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0 }
  },
  [POSITIONS.RIGHT]: {
    initial: { opacity: 0, x: 20 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0 }
  },
  [POSITIONS.TOP]: {
    initial: { opacity: 0, y: -20 },
    animate: { opacity: 1, y: 0 },
    exit: { opacity: 0 }
  },
  [POSITIONS.BOTTOM]: {
    initial: { opacity: 0, y: 20 },
    animate: { opacity: 1, y: 0 },
    exit: { opacity: 0 }
  }
};

const ARROW_SIZE = 10;

const arrowPositions = {
  [POSITIONS.RIGHT]: `
    left: -${ARROW_SIZE / 2 + 1}px;
    top: ${ARROW_SIZE}px;
    border-left-width: 1px;
    border-bottom-width: 1px;
  `,
  [POSITIONS.LEFT]: `
    right: -${ARROW_SIZE / 2 + 1}px;
    top: ${ARROW_SIZE}px;
    border-right-width: 1px;
    border-top-width: 1px;
  `,
  [POSITIONS.TOP]: `
    bottom: -${ARROW_SIZE / 2 + 1}px;
    left: calc(45% - ${ARROW_SIZE / 2}px);
    border-bottom-width: 1px;
    border-right-width: 1px;
  `,
  [POSITIONS.BOTTOM]: `
    top: -${ARROW_SIZE / 2 + 1}px;
    left: calc(45% - ${ARROW_SIZE / 2}px);
    border-left-width: 1px;
    border-top-width: 1px;
  `
};

const AnimatedTooltipContentContainer = styled(motion.div)`
  ${tw`bg-white border-solid border border-grey-medium absolute z-20 inline items-center justify-center shadow normal-case font-normal tracking-normal font-sans text-xs rounded-lg`}
  pointer-events: 'none';
  width: max-content;
  overflow-wrap: break-word;

  &::before {
    width: ${ARROW_SIZE}px;
    height: ${ARROW_SIZE}px;
    ${tw`absolute block bg-white border-solid border-0 border-grey-medium`}
    content:'';
    transform: rotate(45deg);
    ${props => arrowPositions[props.position]};
  }
`;

const TooltipPopup = ({
  visible,
  position,
  tooltip: passedTooltip,
  styles
}) => {
  return (
    <AnimatePresence>
      {visible && (
        <AnimatedTooltipContentContainer
          {...animStates[position]}
          transition={{
            type: 'spring',
            stiffness: 600,
            damping: 50
          }}
          style={styles}
          position={position}>
          {passedTooltip}
        </AnimatedTooltipContentContainer>
      )}
    </AnimatePresence>
  );
};

TooltipPopup.propTypes = {
  position: PropTypes.oneOf(Object.values(POSITIONS)).isRequired,
  tooltip: PropTypes.element.isRequired,
  visible: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  styles: PropTypes.object.isRequired
};

export default TooltipPopup;
