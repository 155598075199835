import React, { useMemo } from 'react';
import dayjs from 'dayjs';
import { useHistory } from 'react-router-dom';

import { useQuery } from '@ubisend/pulse-hooks';
import {
  StretchPanel,
  Table,
  TableHead,
  TableRow,
  TableHeadCell,
  TableBody,
  TableCell,
  Pagination,
  ModalPortal,
  ModalContainer,
  ModalContent,
  ModalClose,
  Flex,
  Heading2,
  usePaginationReducer
} from '@ubisend/pulse-components';

import Scope, { scopes } from '../../../Components/Scope';

const orderedScopes = Object.keys(scopes);

const defaultStart = new Date(dayjs().subtract(1, 'weeks'));
const defaultEnd = new Date(dayjs());

const RecordedMetrics = ({ match, location }) => {
  const history = useHistory();

  const pagination = usePaginationReducer({
    options: { perPageOptions: null }
  });

  const start = location.state?.start || defaultStart;
  const end = location.state?.end || defaultEnd;

  const recordedQuery = useQuery({
    queryKey: [
      `metrics/${match.params.id}/recorded`,
      { ...pagination.params, start, end }
    ],
    keepPreviousData: true,
    staleTime: Infinity
  });
  const metricQuery = useQuery({
    queryKey: [`metrics/${match.params.id}`, { start, end }]
  });

  const handleClose = () => {
    history.push('/dashboard');
  };

  const usedScopes = useMemo(() => {
    if (!recordedQuery.data) {
      return [];
    }

    return (
      recordedQuery.data.data
        // Get each unique scope.
        .reduce((scopes, record) => {
          for (const scope of record.scopes) {
            if (scope.type && !scopes.includes(scope.type)) {
              scopes = scopes.concat(scope.type);
            }
          }

          return scopes;
        }, [])
        // Sort scopes by priority order, ensuring a consistent order
        // of scopes.
        .sort((a, b) => {
          return (
            orderedScopes.findIndex(scope => scope === a) -
            orderedScopes.findIndex(scope => scope === b)
          );
        })
    );
  }, [recordedQuery]);

  return (
    <ModalPortal>
      <ModalContainer>
        <ModalContent
          style={{
            width: '100%',
            maxWidth: '62rem',
            maxHeight: '90vh',
            overflowY: 'auto'
          }}>
          <Flex between fat pad center middle>
            <Heading2>
              {metricQuery.isSuccess ? metricQuery.data.name : 'Metric History'}
            </Heading2>
            <ModalClose onClick={handleClose} />
          </Flex>
          <StretchPanel>
            <Table loading={recordedQuery.isFetching}>
              {recordedQuery.data && (
                <>
                  <TableHead>
                    <TableRow>
                      <TableHeadCell>Recorded</TableHeadCell>
                      {usedScopes.length > 0 ? (
                        usedScopes.map((scope, key) => (
                          <TableHeadCell key={key}>
                            {scopes[scope] ? scopes[scope].name : scope}
                          </TableHeadCell>
                        ))
                      ) : (
                        <TableHeadCell />
                      )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {recordedQuery.data.data.map((record, key) => (
                      <TableRow key={key}>
                        <TableCell>
                          {dayjs(record.created_at).format(
                            'DD/MM/YYYY HH:mm:ss'
                          )}
                        </TableCell>
                        {usedScopes.length > 0 ? (
                          usedScopes.map((scope, key) => {
                            const recordScope = record.scopes.find(
                              recordScope => recordScope.type === scope
                            );
                            return (
                              <TableCell key={key}>
                                {recordScope ? (
                                  <Scope {...recordScope} />
                                ) : (
                                  `${
                                    scopes[scope] ? scopes[scope].name : scope
                                  } has been deleted`
                                )}
                              </TableCell>
                            );
                          })
                        ) : (
                          <TableCell>No additional data stored</TableCell>
                        )}
                      </TableRow>
                    ))}
                  </TableBody>
                </>
              )}
            </Table>
            {recordedQuery.data && (
              <Pagination
                pagination={recordedQuery.data.meta}
                {...pagination.props}
              />
            )}
          </StretchPanel>
        </ModalContent>
      </ModalContainer>
    </ModalPortal>
  );
};

export default RecordedMetrics;
