import Trash from './Trash';
import Sparkles from './Sparkles';
import Save from './Save';
import ArrowNarrowLeft from './ArrowNarrowLeft';
import ArrowNarrowRight from './ArrowNarrowRight';
import ArrowNarrowDown from './ArrowNarrowDown';
import CheckCircle from './CheckCircle';
import InformationCircle from './InformationCircle';
import X from './X';
import XCircle from './XCircle';
import EmojiSad from './EmojiSad';
import Eye from './Eye';
import User from './User';
import LockClosed from './LockClosed';
import Plus from './Plus';
import Archive from './Archive';
import Exclamation from './Exclamation';
import PaperAirplane from './PaperAirplane';
import Ticket from './Ticket';
import LockOpen from './LockOpen';
import ChatAlt from './ChatAlt';
import BadgeCheck from './BadgeCheck';
import CreditCard from './CreditCard';
import Document from './Document';
import PencilAlt from './PencilAlt';
import EyeOff from './EyeOff';
import ClipBoard from './ClipBoard';
import ClipboardCopy from './ClipboardCopy';
import Template from './Template';
import ChartBar from './ChartBar';
import UserGroup from './UserGroup';
import Home from './Home';
import Users from './Users';
import Chat from './Chat';
import LightningBolt from './LightningBolt';
import Link from './Link';
import ColorSwatch from './ColorSwatch';
import Cog from './Cog';
import Pencil from './Pencil';
import BookOpen from './BookOpen';
import SwitchHorizontal from './SwitchHorizontal';
import ViewGridAdd from './ViewGridAdd';
import Chip from './Chip';
import CubeTransparent from './CubeTransparent';
import Rss from './Rss';
import LightBulb from './LightBulb';
import PaperAirplaneRight from './PaperAirplaneRight';
import Variable from './Variable';
import DocumentSearch from './DocumentSearch';
import Key from './Key';
import Adjustments from './Adjustments';
import Beaker from './Beaker';
import Hand from './Hand';
import Minus from './Minus';
import Maximise from './Maximise';
import Minimise from './Minimise';
import ChartPie from './ChartPie';
import Puzzle from './Puzzle';
import Gift from './Gift';
import Mail from './Mail';
import ArrowsExpand from './ArrowsExpand';
import QrCode from './QrCode';
import Download from './Download';
import Logout from './Logout';
import Refresh from './Refresh';
import Clock from './Clock';
import QuestionMarkCircle from './QuestionMarkCircle';
import Calendar from './Calendar';
import SortAscending from './SortAscending';
import SortDescending from './SortDescending';
import Selector from './Selector';
import ChevronUp from './ChevronUp';
import ChevronDown from './ChevronDown';
import Filter from './Filter';
import Code from './Code';
import ExclamationCircle from './ExclamationCircle';

const outlineIcons = {
  questionMarkCircle: QuestionMarkCircle,
  arrowsExpand: ArrowsExpand,
  gift: Gift,
  chartPie: ChartPie,
  beaker: Beaker,
  adjustments: Adjustments,
  variable: Variable,
  lightBulb: LightBulb,
  airplaneRight: PaperAirplaneRight,
  rss: Rss,
  cubeTransparent: CubeTransparent,
  chip: Chip,
  viewGridAdd: ViewGridAdd,
  trash: Trash,
  arrowNarrowDown: ArrowNarrowDown,
  arrowNarrowLeft: ArrowNarrowLeft,
  arrowNarrowRight: ArrowNarrowRight,
  checkCircle: CheckCircle,
  informationCircle: InformationCircle,
  x: X,
  xCircle: XCircle,
  emojiSad: EmojiSad,
  save: Save,
  eye: Eye,
  user: User,
  minus: Minus,
  lockClosed: LockClosed,
  plus: Plus,
  archive: Archive,
  exclamation: Exclamation,
  airplane: PaperAirplane,
  ticket: Ticket,
  lockOpen: LockOpen,
  chatAlt: ChatAlt,
  badgeCheck: BadgeCheck,
  creditCard: CreditCard,
  document: Document,
  pencilAlt: PencilAlt,
  eyeOff: EyeOff,
  clipBoard: ClipBoard,
  clipboardCopy: ClipboardCopy,
  template: Template,
  chartBar: ChartBar,
  userGroup: UserGroup,
  home: Home,
  hand: Hand,
  users: Users,
  chat: Chat,
  lightningBolt: LightningBolt,
  link: Link,
  colorSwatch: ColorSwatch,
  cog: Cog,
  pencil: Pencil,
  bookOpen: BookOpen,
  switchHorizontal: SwitchHorizontal,
  documentSearch: DocumentSearch,
  key: Key,
  maximise: Maximise,
  minimise: Minimise,
  puzzle: Puzzle,
  mail: Mail,
  qrCode: QrCode,
  download: Download,
  logout: Logout,
  sparkles: Sparkles,
  refresh: Refresh,
  clock: Clock,
  calendar: Calendar,
  sortAscending: SortAscending,
  sortDescending: SortDescending,
  selector: Selector,
  chevronDown: ChevronDown,
  chevronUp: ChevronUp,
  filter: Filter,
  code: Code,
  exclamationCircle: ExclamationCircle
};

export default outlineIcons;
