import React from 'react';
import PropTypes from 'prop-types';

import { TextInput, Label } from '@ubisend/pulse-components';

const UniqueInput = ({ content, onChange }) => {
  const handleChange = event => {
    const uniqueKey = event.target.value;

    onChange({ uniqueKey });
  };

  return (
    <div>
      <Label htmlFor="unique-key">Key to use</Label>
      <TextInput
        id="unique-key"
        placeholder="optional"
        value={content.uniqueKey}
        onChange={handleChange}
      />
    </div>
  );
};

UniqueInput.propTypes = {
  content: PropTypes.shape({
    uniqueKey: PropTypes.string.isRequired
  }).isRequired,
  onChange: PropTypes.func.isRequired
};

export default UniqueInput;
