import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { useQuery } from '@ubisend/pulse-hooks';
import {
  Button,
  StretchPanel as Panel,
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  TableActions,
  Pagination,
  Span,
  usePaginationReducer,
  OrderableTableRow,
  useOrderableTableReducer
} from '@ubisend/pulse-components';
import { PermissionFilter } from '@ubisend/pulse-auth';

import TicketStatus from './TicketStatus';
import TicketCreatedAt from './TicketCreatedAt';
import TicketNameTableCell from './TicketNameTableCell';

const AgentTickets = ({ id }) => {
  const pagination = usePaginationReducer({ id: `agent-tickets-${id}` });
  const order = useOrderableTableReducer({ id: `agent-tickets-${id}` });

  const query = useQuery([
    'tickets/ubidesk',
    {
      ...pagination.params,
      ...order.params,
      ticket_types: ['regular', 'live-chat'],
      statuses: [],
      assignee_ids: [id]
    }
  ]);

  if (query.showNoResultsMessage) {
    return <Span>No tickets have been worked on yet.</Span>;
  }

  return (
    <Panel>
      <Table loading={query.isLoading} loadingColumns={4}>
        <TableHead>
          <OrderableTableRow
            rows={[
              { label: 'Summary', sort: 'summary' },
              { label: 'Status', sort: 'status' },
              { label: 'Date', sort: 'created_at' },
              null
            ]}
            {...order.props}
          />
        </TableHead>
        {query.isSuccess && (
          <TableBody>
            {query.data.data.map((ticket, key) => (
              <TableRow key={key}>
                <TicketNameTableCell>{ticket.name}</TicketNameTableCell>
                <TableCell>
                  <TicketStatus>{ticket.status}</TicketStatus>
                </TableCell>
                <TableCell>
                  <TicketCreatedAt date={ticket.created_at} />
                </TableCell>
                <TableActions>
                  <PermissionFilter can="view tickets">
                    <Button
                      as={Link}
                      variant="secondary"
                      to={`/tickets/${ticket.id}`}
                      icon="eye">
                      View
                    </Button>
                  </PermissionFilter>
                </TableActions>
              </TableRow>
            ))}
          </TableBody>
        )}
      </Table>
      {query.showPagination && (
        <Pagination pagination={query.data.meta} {...pagination.props} />
      )}
    </Panel>
  );
};

AgentTickets.propTypes = {
  id: PropTypes.number.isRequired
};

export default AgentTickets;
