import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { Flex, Button, ToggleButton, Span } from '@ubisend/pulse-components';

import SubjectSelect from '../../SubjectSelect';
import { useConditionalTypes, useConditional } from '../../../hooks/index';

const EventConditional = ({ index, labelPrefix, type, flipped, subject }) => {
  const types = useConditionalTypes();
  const {
    conditionals,
    match,
    handleConditionalDelete,
    handleSubjectChange,
    handleFlippedToggle,
    getSubjectValue
  } = useConditional();

  const label = (string, index) => `${labelPrefix}-${string}-${index}`;

  const conditionalType = useMemo(() => {
    const conditional = types.find(({ name }) => name === type);

    if (!conditional) {
      return null;
    }

    return conditional.type;
  }, [types, type]);

  return (
    <Flex col ySpace>
      <Flex xSpace center>
        <Span uppercase style={{ minWidth: '4rem', textAlign: 'right' }}>
          {index === 0 && 'if'}
          {index !== 0 && match === 'any' && 'Or'}
          {index !== 0 && match === 'all' && 'And'}
        </Span>
        <ToggleButton
          on={flipped}
          onChange={handleFlippedToggle(index)}
          aria-label={label('flipped', index)}>
          not
        </ToggleButton>
        <SubjectSelect
          aria-label={label('subject', index)}
          value={getSubjectValue({ conditionalType, type, subject })}
          onChange={handleSubjectChange(index)}
        />
        <Flex grow right>
          <Button
            variant="secondary"
            colour="danger"
            icon="trash"
            disabled={conditionals.length <= 1}
            onClick={handleConditionalDelete(index)}>
            Delete
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );
};

EventConditional.propTypes = {
  index: PropTypes.number.isRequired,
  labelPrefix: PropTypes.string.isRequired,
  subject: PropTypes.shape({
    type: PropTypes.string
  }).isRequired,
  type: PropTypes.string,
  condition: PropTypes.string,
  case_sensitive: PropTypes.bool,
  flipped: PropTypes.bool
};

export default EventConditional;
