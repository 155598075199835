import React, { useRef } from 'react';
import PropTypes from 'prop-types';

import {
  useQuery,
  createInitialMessages,
  formatSettings,
  DemoDeliveryProvider,
  BotProvider,
  ThemeProvider,
  SubscriberTypingProvider,
  AuthContext,
  LanguageProvider,
  useExternallyChangableState
} from '@ubisend/pulse-volt';

const channels = {
  converse: {
    driver: 'Converse',
    query: 'bot/converse/auth',
    namespaces: ['bots', 'converse']
  },
  embed: {
    driver: 'Embedded',
    query: 'bot/embed/auth',
    namespaces: ['bots', 'embed']
  },
  'full-page': {
    driver: 'Full Page',
    query: 'bot/full-page/auth',
    namespaces: ['bots', 'full_page']
  }
};

const formatQueryData = data => {
  const { bot, styles } = formatSettings(data.settings);

  return {
    styles,
    language: data.language,
    bot: {
      ...bot,
      initialMessages: createInitialMessages(data.messages)
    }
  };
};

const DemoProvider = ({
  children,
  client,
  base_url,
  bot: chosenBot,
  token: passedToken = null
}) => {
  const channel = channels[chosenBot];

  const [token, setToken] = useExternallyChangableState(passedToken);

  const resultRef = useRef();
  const botRef = useRef(chosenBot);

  const query = useQuery(
    [channel.query, { token, channelDriverName: channel.driver, demo: true }],
    {
      // Pulse params
      baseUrl: base_url,
      token,
      clientId: client,
      browserUrl: null,
      variables: [],
      channelDriverName: channel.driver,
      // Query params
      // Run query: on the first render, if the token is manually updated, or
      // if bot is changed
      enabled: Boolean(
        !(token && resultRef.current) ||
          resultRef.current !== token ||
          botRef.current !== chosenBot
      ),
      // Keep previous data if staying on the same bot.
      // We don't want to pass the Converse theme to Full page for example.
      keepPreviousData: botRef.current === chosenBot,
      cacheTime: 0,
      onSuccess: data => {
        resultRef.current = data.token;
        botRef.current = chosenBot;
        setToken(data.token);
      }
    }
  );

  if (!query.isSuccess) {
    return null;
  }

  const { bot, styles, language } = formatQueryData(query.data);

  return (
    <AuthContext.Provider value={{ token: query.data.token, setToken, query }}>
      <ThemeProvider value={styles}>
        <BotProvider
          {...bot}
          showClose={false}
          showBanner={false}
          showReset={true}>
          <DemoDeliveryProvider
            client_id={client}
            channelDriverName={channel.driver}
            browserUrl={null}
            variables={[]}
            base_url={base_url}>
            <LanguageProvider
              initialLanguage={language}
              namespaces={channel.namespaces}>
              <SubscriberTypingProvider>{children}</SubscriberTypingProvider>
            </LanguageProvider>
          </DemoDeliveryProvider>
        </BotProvider>
      </ThemeProvider>
    </AuthContext.Provider>
  );
};

DemoProvider.propTypes = {
  client: PropTypes.string.isRequired,
  base_url: PropTypes.string.isRequired,
  token: PropTypes.string,
  bot: PropTypes.oneOf(Object.keys(channels))
};

export default DemoProvider;
