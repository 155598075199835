import BooleanDefaultValueInput from './BooleanDefaultValueInput';
import BooleanPreview from './BooleanPreview';

const booleanType = {
  key: 'Boolean',
  name: 'True or False',
  default_value: true,
  DefaultValueInput: BooleanDefaultValueInput,
  Preview: BooleanPreview
};

export default booleanType;
