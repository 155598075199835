import LeaveFeedbackContent from './LeaveFeedbackContent';

const leaveFeedback = {
  name: 'Leave feedback',
  Content: LeaveFeedbackContent,
  setInitialContent: (content = {}) => {
    return {
      feedback: content.feedback || ''
    };
  },
  valid: content => {
    return Boolean(content.feedback);
  }
};

export default leaveFeedback;
