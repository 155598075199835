import { useReducer } from 'react';

const TYPES = {
  SHOW_NOTIFICATION: 'SHOW_NOTIFICATION',
  SHOW_WARNING: 'SHOW_WARNING',
  SHOW_ERROR: 'SHOW_ERROR',
  HIDE_NOTIFICATION: 'HIDE_NOTIFICATION'
};

const NOTIFICATION_TYPES = {
  WARNING: 'WARNING',
  ERROR: 'ERROR'
};

const reducer = (state, { type, ...params }) => {
  switch (type) {
    case TYPES.SHOW_NOTIFICATION:
      return {
        type: NOTIFICATION_TYPES[params.notificationType],
        message: params.message
      };
    case TYPES.SHOW_WARNING:
      return {
        type: NOTIFICATION_TYPES.WARNING,
        message: params.message
      };
    case TYPES.SHOW_ERROR:
      return {
        type: NOTIFICATION_TYPES.ERROR,
        message: params.message
      };
    case TYPES.HIDE_NOTIFICATION:
      return { type: null, message: null };
    default:
      throw new Error(`No event defined in useNotificationReducer for ${type}`);
  }
};

const defaultNotification = {
  type: null,
  message: null
};

const useNotificationReducer = (initialNotification = defaultNotification) => {
  const [notification, dispatch] = useReducer(reducer, initialNotification);

  const hasNotification = Boolean(notification.type);

  return { notification, dispatch, hasNotification, TYPES, NOTIFICATION_TYPES };
};

export default useNotificationReducer;
export { NOTIFICATION_TYPES };
