import SetLanguageContent from './SetLanguageContent';

const setLanguage = {
  name: 'Set subscriber language',
  Content: SetLanguageContent,
  setInitialContent: (content = {}) => {
    return {
      language: content.language || ''
    };
  },
  valid: content => {
    return Boolean(content.language);
  }
};

export default setLanguage;
